<app-navbar *ngIf="isReferal"></app-navbar>
<div [ngClass]="{'container' : isReferal}">
  <div [ngClass]="{'container-fluid' : isReferal}">
    <div class="content-box bg-grey1 px-0" [ngClass]="!isUserLogged ? '' : 'user-dashboard'" *ngIf="!isPaymentLink">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"  type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
    <div class="container-fluid px-0" [ngStyle]="{'margin-bottom':!isUserLogged ? '' : ''}"
        [ngClass]="!isUserLogged ? '' : ''">    
        <div class="container bg-grey9 pb-4 px-0">
            <div class="row">
                <div class="col-md-12 mb-1" >
                    <form action="#" #vehical_details="ngForm" class="vehicle-form">
                      <div class="row card m-0 flex-row  py-2">
                        <div class="col-md-auto d-flex align-items-center">
                          <button type="button" class="btn btn-primary px-1  py-1"><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft" class="cursor-pointer svg_white edit-icon" (click)="goTo()"></button>
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col-md-3 text-wrap">
                              <label for="" class="mb-md-0 mb-1">Manufacturer</label>
                              <span title="{{vehicleData.make}}">{{vehicleData.make}}</span>                              
                            </div>
                            <div class="col-md-2 text-wrap">
                              <label for="" class="mb-md-0 mb-1">Model</label>
                              <span title="{{vehicleData.model}}">{{vehicleData.model}}</span>
                            </div>
                            <div class="col-md-2 col-6 text-wrap">
                              <label for="" class="mb-md-0 mb-1">Variant</label>
                              <span title="{{vehicleData.variant}}">{{vehicleData.variant}}</span>
                            </div>
                            <div class="col-md-2 col-6 text-wrap">
                              <label for="" class="mb-md-0 mb-1">RTO</label>
                              <span title="{{(vehicleData.registrationNumber && vehicleData.registrationNumber!= '' ? vehicleData.registrationNumber : vehicleData.rto)}} - {{vehicleData.city}}">
                                {{(vehicleData.registrationNumber && vehicleData.registrationNumber!= "" ? vehicleData.registrationNumber : vehicleData.rto)}} - {{vehicleData.city}}</span>
                            </div>    
                            <div class="col-md-1 col-6 text-wrap">
                              <label for="" class="mb-md-0 mb-1">Year</label>
                              <span title="{{vehicleData.registrationYear}}">{{vehicleData.registrationYear}}</span>
                            </div>
                            <div class="col-md-2 col-6 text-wrap">
                                <label for="" class="mb-md-0 mb-1">Last Year Claim</label>
                                <span title="{{vehicleData.claimsMadeInPreviousPolicy}}">{{vehicleData.claimsMadeInPreviousPolicy}}</span>
                              </div>
                              <!-- <div class="col-md-1 col-6">
                                <label for="" class="mb-md-0 mb-1">NCB Last Year</label>
                                <span>{{vehicleData.ncbprevious}}%</span>
                              </div> -->
                          </div>
                          <div class="row justify-content-start pt-2 border-top" *ngIf="this.insuranceformData.vehicleType == 3">
                            <div class="col"><label for="" class="d-inline">Category : </label> {{this.vehicleData.category}}</div>
                            <div class="col"><label for="" class="d-inline">Permit Type : </label> {{this.vehicleData.permit}}</div>
                            <div class="col" *ngIf="this.vehicleData.category == 'PCV'"><label for="" class="d-inline">PCV Vehicle Type : </label> {{this.vehicleData.pcVehicle}}</div>
                            <div class="col" *ngIf="this.vehicleData.category == 'GCV'"><label for="" class="d-inline">GCV Vehicle Type : </label> {{this.vehicleData.gcVehicle}}</div>
                            <div class="col" *ngIf="this.vehicleData.category == 'MISC'"><label for="" class="d-inline">Misc Vehicle Type : </label> {{this.vehicleData.miscVehicle}}</div>
                            <div class="col" *ngIf="this.vehicleData.category == 'PCV' && this.vehicleData.pcVehicle == 'Bus'"><label for="" class="d-inline">Seating Capacity : </label> {{this.vehicleData.seatingCap}} Seater</div>
                            <div class="col" *ngIf="this.vehicleData.category == 'PCV' && this.vehicleData.pcVehicle == 'Bus'"><label for="" class="d-inline">Purpose : </label> {{this.vehicleData.purpose}}</div>
                            <div class="col" *ngIf="this.vehicleData.category == 'GCV' || this.vehicleData.category == 'MISC'"><label for="" class="d-inline">Gross Vehilce Weight : </label> {{this.vehicleData.gVW}} Ton</div>
                          </div>
                        </div>
                      </div>
                    </form>    
                </div>
                <!-- <div class="col-12" *ngIf="isSummry">
                    <div class="row card m-0 flex-row  py-2 justify-content-between">
                        <div class="col d-flex align-items-center">
                          <button type="button" class="btn btn-primary px-1 py-1"><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft" class="cursor-pointer svg_white edit-icon" (click)="showMainDetails()"></button>
                          <h2 class="mb-0 ml-3">Summary</h2> 
                        </div>
                    </div>
                </div> -->
                <div class="col-md-4 mt-3">
                    <div class="premium_breakup card p-5">
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <img _ngcontent-shp-c72="" alt="" class="image-stl" height="60" src="/assets/images/insurance_companies/{{companyLogoId}}.png">
                            </div>
                            <div class="col-md-8 col-6">
                                <div class="row">
                                    <div class="col-12 text-right">
                                        Plan : <span class="fw-medium">{{plan}}</span>
                                    </div>
                                    <div class="col-12 text-right" *ngIf="!isThirdPArty">
                                        IDV : <span class="fw-medium">₹ {{idv | thousandSeparator}}</span>
                                    </div>
                                    <div class="col-12 text-right" *ngIf="!isThirdPArty">
                                        Tenure : <span class="fw-medium">{{tenure}} Year</span>
                                    </div> 
                                    <div class="col-12 text-right" *ngIf="!isThirdPArty">
                                        {{tenurePeriod}}
                                    </div>
                                   
                                </div>                                
                              </div>                              
                        </div>    
                        <hr class="premium_breakup my-0">                
                        <div class="row mt-2">
                            <div class="col-12">
                                <h4>Premium Breakup</h4>
                            </div>
                        </div>
                        <hr class="premium_breakup my-0">
                        <!-- <div class="row" *ngIf="checkValueNotZero(premiumData.ownDriverCover??'0')">
                            <div class="col-8 mt-3">
                              <span>Basic Own Damage Premium</span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.ownDriverCover??'0' | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.thirdPartyCoverPremium??'0')">
                            <div class="col-8  mt-3">
                              <span>Third Party Property Damage </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.thirdPartyCoverPremium??'0' | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.paCover??'0')">
                            <div class="col-8  mt-3">
                              <span>PA Cover To Owner</span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.paCover??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.unamedPassenger??'0')">
                            <div class="col-8  mt-3">
                              <span>Unnamed PA Cover </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.unamedPassenger??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.paToPaidDriver??'0')">
                            <div class="col-8  mt-3">
                              <span>PA To Paid Driver </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.paToPaidDriver??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.paToEmployee??'0')">
                            <div class="col-8  mt-3">
                              <span>PA To Employee </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.paToEmployee??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.paToPaidConductor??'0')">
                            <div class="col-8  mt-3">
                              <span>PA To Conductor </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.paToPaidConductor??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.paToPaidCleaner??'0')">
                            <div class="col-8  mt-3">
                              <span>PA To Cleaner </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.paToPaidCleaner??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.llToPaidDriver??'0')">
                            <div class="col-8  mt-3">
                              <span>LL To Paid Driver </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.llToPaidDriver??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.llToEmployee??'0')">
                            <div class="col-8  mt-3">
                              <span>LL To Employee </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.llToEmployee??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.llToNonEmployee??'0')">
                            <div class="col-8  mt-3">
                              <span>LL To Non-Employee </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.llToNonEmployee??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.llToPaidConductor??'0')">
                            <div class="col-8  mt-3">
                              <span>LL To Conductor </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.llToPaidConductor??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.llToPaidCleaner??'0')">
                            <div class="col-8  mt-3">
                              <span>LL To Cleaner </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.llToEmployee??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.biFuelKit??'0')">
                            <div class="col-8  mt-3">
                              <span>Bi-Fuel Kit </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.biFuelKit??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.electricalAccessories??'0')">
                            <div class="col-8  mt-3">
                              <span>Electrical Accessories </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.electricalAccessories??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.nonElectricalAccessories??'0')">
                            <div class="col-8  mt-3">
                              <span>Non Electrical Accessories </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.nonElectricalAccessories??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>

                          <div class="row" *ngIf="checkValueNotZero(premiumData.fpP_Premium??'0')">
                            <div class="col-8  mt-3">
                              <span>FPP Cover</span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.fpP_Premium??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.nfpP_Premium??'0')">
                            <div class="col-8  mt-3">
                              <span>NFPP Cover </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.nfpP_Premium??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.trailers_Premium??'0')">
                            <div class="col-8  mt-3">
                              <span>Trailers Cover </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.trailers_Premium??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.addOnValue??'0')">
                            <div class="col-8  mt-3">
                              <span>Addons Amount  <span class="text-main font-10 cursor-pointer" (click)="showHideAddonDetails()">{{ showDetails ? 'Hide Details' : 'Show Details'}}</span></span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.addOnValue??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div *ngIf="showDetails && selectedAddons.length > 0 && insuranceformData.vehicleDetails?.typeOfCover != 'LiabilityOnly'" class="card px-4 py-2 mb-2">
                            <div class="row">
                                <div class="col-6  mt-1" *ngFor="let addon of selectedAddons" >
                                  <span>{{addon.label}}</span>
                                </div>
                              </div>
                          </div>                          
                          <div class="row" *ngIf="checkValueNotZero(premiumData.ncB_Discount??'0')">
                            <div class="col-8  mt-3">
                              <span>NCB Discount({{vehiclePremiumData.vehicleData.ncbCurrent}}%)</span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5 class="text-success">- ₹ {{premiumData.ncB_Discount??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.antiTheftDisc??'0')">
                            <div class="col-8  mt-3">
                              <span>Anti Theft Device Discount </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5 class="text-success">- ₹ {{premiumData.antiTheftDisc??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row" *ngIf="checkValueNotZero(premiumData.tppD_Discount??'0')">
                            <div class="col-8  mt-3">
                              <span>TPPD Discount </span>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5 class="text-success">- ₹ {{premiumData.tppD_Discount??"0" | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <hr class="premium_breakup my-0">
                          <div class="row">
                            <div class="col-8 mt-3">
                              <h5>Net Premium</h5>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.netPremium??'0' | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-8 mt-3">
                              <span>GST(18%)</span>
                            </div>
                            <div class="col-4 mt-3 text-right" >
                              <h5 class="text-danger">+ ₹ {{premiumData.serviceTaxValue??'0' | thousandSeparator}}</h5>
                            </div>
                          </div>
                          <hr class="premium_breakup my-0">
                          <div class="row">
                            <div class="col-8 mt-3">
                              <h5>Final Premium</h5>
                            </div>
                            <div class="col-4 mt-3 text-right">
                              <h5>₹ {{premiumData.finalPremium ??'0' | thousandSeparator}}</h5>
                            </div>
                          </div> -->
                          <div class="row" *ngIf="checkValueNotZero(priceBreakup.ownDriverCover??'0')">
                            <div class="col-12 fw-bolder border-top border-bottom py-1 bg-grey2">
                                <span>{{checkValueNotZero(priceBreakup.ownDriverCover??'0') && checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0') ? 'A -' : ''}} Own Damage Premium</span>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.ownDriverCover??'0')">
                            <div class="col-8 mt-2">
                                <span>Basic own Damage Premium</span>
                            </div>
                            <div class="col-4 mt-2 text-right">
                                <h5>₹ {{priceBreakup.ownDriverCover??'0' | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.geographicalExtension_OD??'0')">
                            <div class="col-8  mt-1">
                                <span>Geographical Extension OD </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.geographicalExtension_OD??'0' | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.biFuelKit??'0')">
                            <div class="col-8  mt-1">
                                <span>Bi-Fuel Kit </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.biFuelKit??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.electricalAccessories??'0')">
                            <div class="col-8  mt-1">
                                <span>Electrical Accessories {{this.priceBreakup.insuranceCompany==8? '(Max 20% of IDV)' : ''}}</span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.electricalAccessories??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.nonElectricalAccessories??'0')">
                            <div class="col-8  mt-1">
                                <span>Non Electrical Accessories {{this.priceBreakup.insuranceCompany==8? '(Max 20% of IDV)' : ''}}</span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.nonElectricalAccessories??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.fpP_Premium??'0')">
                            <div class="col-8  mt-1">
                                <span>FPP Cover</span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.fpP_Premium??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.nfpP_Premium??'0')">
                            <div class="col-8  mt-1">
                                <span>NFPP Cover </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.nfpP_Premium??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.trailers_Premium??'0')">
                            <div class="col-8  mt-1">
                                <span>Trailers Cover </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.trailers_Premium??"0" | thousandSeparator}}</h5>
                            </div>
                            </div> 
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.imT23??'0')">
                                <div class="col-8  mt-1">
                                  <span>IMT23 </span>
                                </div>
                                <div class="col-4 mt-1 text-right">
                                  <h5>₹ {{priceBreakup.imT23??"0" | thousandSeparator}}</h5>
                                </div>
                              </div>           
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.ncB_Discount??'0')">
                            <div class="col-8  mt-1">
                                <span>NCB Discount({{insuranceformData.vehicleDetails?.ncbCurrent??"0"}}%)</span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5 class="text-success">- ₹ {{priceBreakup.ncB_Discount??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.deTariffDiscount??'0')">
                                <div class="col-8  mt-1">
                                  <span>De-Tariff Discount</span>
                                </div>
                                <div class="col-4 mt-1 text-right">
                                  <h5 class="text-success">- ₹ {{priceBreakup.deTariffDiscount??"0" | thousandSeparator}}</h5>
                                </div>
                              </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.antiTheftDisc??'0')">
                            <div class="col-8  mt-1">
                                <span>Anti Theft Device Discount </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5 class="text-success">- ₹ {{priceBreakup.antiTheftDisc??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.addOnValue??'0')">
                                <div class="col-8  mt-1">
                                    <span>Addons Amount  <span class="text-main font-10 cursor-pointer" (click)="showHideAddonDetails()">{{ showDetails ? 'Hide Details' : 'Show Details'}}</span></span>
                                </div>
                                <div class="col-4 mt-1 text-right">
                                    <h5>₹ {{priceBreakup.addOnValue??"0" | thousandSeparator}}</h5>
                                </div>
                            </div>
                            <div *ngIf="showDetails && selectedAddons.length > 0 && insuranceformData.vehicleDetails?.typeOfCover != 'LiabilityOnly'" class="card px-4 py-2 mb-2">
                            <div class="row">
                                <div class="col-6  mt-1" *ngFor="let addon of selectedAddons" >
                                    <span>{{addon.label}}</span>
                                </div>
                                </div>
                            </div>   
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0')">
                            <div class="col-12 fw-bolder border-top border-bottom py-1 bg-grey2">
                                <span>{{checkValueNotZero(priceBreakup.ownDriverCover??'0') && checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0') ? 'B -' : ''}} Liability Premium</span>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0')">
                            <div class="col-8  mt-2">
                                <span>Basic Liability </span>
                            </div>
                            <div class="col-4 mt-2 text-right">
                                <h5>₹ {{priceBreakup.thirdPartyCoverPremium??'0' | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.biFuelKit_TP??'0')">
                            <div class="col-8  mt-1">
                                <span>Bi-Fuel Kit </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.biFuelKit_TP??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.geographicalExtension_TP??'0')">
                            <div class="col-8  mt-1">
                                <span>Geographical Extension TP </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.geographicalExtension_TP??'0' | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.paCover??'0')">
                            <div class="col-8  mt-1">
                                <span>PA Cover To Owner</span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.paCover??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.unamedPassenger??'0')">
                            <div class="col-8  mt-1">
                                <span>PA Cover for Unnamed Passenger</span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.unamedPassenger??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.paToPaidDriver??'0')">
                            <div class="col-8  mt-1">
                                <span>PA To Paid Driver </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.paToPaidDriver??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.paToEmployee??'0')">
                            <div class="col-8  mt-1">
                                <span>PA To Employee </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.paToEmployee??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.paToPaidConductor??'0')">
                            <div class="col-8  mt-1">
                                <span>PA To Conductor </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.paToPaidConductor??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.paToPaidCleaner??'0')">
                            <div class="col-8  mt-1">
                                <span>PA To Cleaner </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.paToPaidCleaner??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToPaidDriver??'0')">
                            <div class="col-8  mt-1">
                                <span>LL To Paid Driver </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.llToPaidDriver??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToPassenger??'0')">
                                <div class="col-8  mt-1">
                                  <span>LL To Passengers </span>
                                </div>
                                <div class="col-4 mt-1 text-right">
                                  <h5>₹ {{priceBreakup.llToPassenger??"0" | thousandSeparator}}</h5>
                                </div>
                              </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToEmployee??'0')">
                            <div class="col-8  mt-1">
                                <span>LL To Employee </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.llToEmployee??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToNonEmployee??'0')">
                            <div class="col-8  mt-1">
                                <span>LL To Non-Employee </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.llToNonEmployee??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToPaidConductor??'0')">
                            <div class="col-8  mt-1">
                                <span>LL To Conductor </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.llToPaidConductor??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToPaidCleaner??'0')">
                            <div class="col-8  mt-1">
                                <span>LL To Cleaner </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.llToPaidCleaner??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            
                            <div class="row" *ngIf="checkValueNotZero(priceBreakup.tppD_Discount??'0')">
                            <div class="col-8  mt-1">
                                <span>TPPD Discount </span>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5 class="text-success">- ₹ {{priceBreakup.tppD_Discount??"0" | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <hr class="premium_breakup my-1">
                            <div class="row">
                            <div class="col-8 mt-1">
                                <h5>Net Premium {{checkValueNotZero(priceBreakup.ownDriverCover??'0') && checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0') ? '(A + B)' : ''}}</h5>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.netPremium??'0' | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <div class="row">
                            <div class="col-8 mt-1">
                                <span>GST(18%)</span>
                            </div>
                            <div class="col-4 mt-1 text-right" >
                                <h5 class="text-danger">+ ₹ {{priceBreakup.serviceTaxValue??'0' | thousandSeparator}}</h5>
                            </div>
                            </div>
                            <hr class="premium_breakup my-1">
                            <div class="row">
                            <div class="col-8 mt-1">
                                <h5>Final Premium</h5>
                            </div>
                            <div class="col-4 mt-1 text-right">
                                <h5>₹ {{priceBreakup.finalPremium ??'0' | thousandSeparator}}</h5>
                            </div>
                            </div>
                          <div class="row mt-2" *ngIf="isSummry">
                            <div class="col-12">
                              <button class="btn btn-primary w-100" (click)="proceedToBuy()">Pay Securely</button>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="col-md-8 mt-3"> 
                    <ng-container *ngIf="!isSummry">                   
                        <div class="row">
                            <div class="col-12">
                            <ul class="card-item card d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center">
                                <li class="list-item col" [ngClass]="{'active': detail == 'owner'}" (click)="changeTab('owner')">
                                    Owner Details 
                                </li>
                                <li class="list-item  col" [ngClass]="{'active': detail == 'address'}" (click)="changeTab('address')">
                                    Address Details
                                </li>
                                <li class="list-item  col" [ngClass]="{'active': detail == 'nominee'}" (click)="changeTab('nominee')" *ngIf="showNomineeForm == true">
                                    Nominee Details
                                </li>
                                <li class="list-item  col" [ngClass]="{'active': detail == 'vehicle'}" (click)="changeTab('vehicle')">
                                    Vehicle Details
                                </li>
                                <li class="list-item  col" [ngClass]="{'active': detail == 'policy'}" *ngIf="showExistingPolicyControls || showTPDetailsForm" (click)="changeTab('policy')">
                                    Existing Policy Details
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div class="tab-content clearfix mb-5">
                            <div id="ownerdetails" class="tab-pane  col-12 c-pl c-pr" [ngClass]="{'active': detail == 'owner'}">
                            <ng-container >
                                <div class="card py-5 px-0 row br-top-0">
                                        <div class="details_card">
                                            <form id="kt_account_profile_details_form" class="form" [formGroup]="userForm"
                                                novalidate="novalidate">
                                                <div class="row" *ngIf="showPANForm16 && showForm16">
                                                    <div class="col-12">
                                                        <ul class="card-item card d-flex justify-content-between align-items-center mb-0 flex-row p-2 text-center">
                                                            <li class="list-item col py-1" [ngClass]="{'active': selectedOptionTab == 'pan'}" (click)="changeOptionTab('pan')">
                                                                PAN Card 
                                                            </li>
                                                            <li class="list-item col py-1" [ngClass]="{'active': selectedOptionTab == 'form16'}" (click)="changeOptionTab('form16')">
                                                                Form 60
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div [ngClass]="{'tab-content clearfix mb-5' : showPANForm16}">
                                                    <div id="ownerdetails1" [ngClass]="{'tab-pane  col-12 c-pl c-pr active' : showPANForm16}">
                                                        <div class="row" [ngClass]="{'card pb-5 br-top-0' : showPANForm16}">
                                                            <div class="col-12">
                                                                <div class="justify-content-between row">
                                                                    <div class="" [ngClass]="{'col-md-6': showPANForm16, 'col-md-12' : !showPANForm16}">
                                                                        <div class="row">
                                                                            <div class="mt-3 col-md-6" *ngIf="!showPANForm16 || (showPANForm16 && selectedOptionTab == 'pan')">
                                                                                <label for="name" class="required">PAN Card</label>                                                            
                                                                                <div class="d-flex">
                                                                                    <input type="text" class="form-control rounded-right1-0" name="pancard"
                                                                                    formControlName="panNumber" placeholder="Pan card" minlength="10" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                                                    maxlength="10" (keypress)="alphaNumericOnly($event)" oninput="this.value = this.value.toUpperCase()"
                                                                                    [ngClass]="{'border border-danger': ((userFormControl['panNumber'].touched  || submitted) && !userFormControl['panNumber'].valid)}">
                                                                                    <button  disabled="" class="btn btn-primary py-0 px-2 rounded-left1-0" [disabled]="!userFormControl['panNumber'].valid" (click)="getPanCardDetails()">
                                                                                        <img  src="../../assets/svgIcon/fetch.svg" alt="Fetch" class="icon-white" title="Fetch Details">
                                                                                    </button>
                                                                                </div>
                                                                                <Span class="text-danger"
                                                                                    *ngIf="((userFormControl['panNumber'].touched  || submitted) && userFormControl['panNumber'].errors?.['required'])">
                                                                                    PAN number is required.
                                                                                </Span>
                                                                                <span class="text-main" *ngIf="showNegativeKycInfo">Once KYC is updated please fetch KYC details again</span>
                                                                                <Span class="text-danger"
                                                                                    *ngIf="((userFormControl['panNumber'].touched  || submitted) && userFormControl['panNumber'].errors?.['pattern'])">
                                                                                    Provide valid PAN number.
                                                                                </Span>
                                                                            </div>
                                                                            <div class="mt-3 col-md-6" *ngIf="showPANForm16 && selectedOptionTab == 'form16'">
                                                                                <label for="name" class="required">Form 60</label>                                                            
                                                                                <div class="d-flex">
                                                                                    <input type="text" class="form-control" name="form16" 
                                                                                    placeholder="Form 60" value="Form 60" readonly="readonly">
                                                                                </div>
                                                                            </div>
                
                                                                        <div class="mt-3 col-md-6">
                                                                            <label class="required" for="dob">{{this.showNomineeForm == false ? 'Date Of Incorporation' :'Date Of Birth'}} </label>
                                                                            <input type="date" placeholder="DD-MM-YYYY" name="dob"  [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                                                formControlName="dateOfBirth" autocomplete="off" [max]="maxDOB" onkeydown="return false" 
                                                                                class="new-user form-control bg-transparent"
                                                                                [ngClass]="{'border border-danger': ((userFormControl['dateOfBirth'].touched  || submitted) && !userFormControl['dateOfBirth'].valid)}" />
                                                                            <Span class="text-danger"
                                                                                *ngIf="((userFormControl['dateOfBirth'].touched  || submitted) && !userFormControl['dateOfBirth'].valid)">
                                                                                Date of birth is required.
                                                                            </Span>
                                                                        </div>
                                                                        <div class="col-md-3 mt-3">
                                                                            <label for="title" class="required">Title</label>
                                                                            <select name="title" id="title" class="form-select form-select-custom"
                                                                                formControlName="title" [disabled]="this.status == 5">
                                                                                <option *ngFor="let title of titleList" [value]="title">
                                                                                    {{title}}
                                                                                </option>
                                                                            </select>                
                                                                        </div>
                                                                        <div class="col-md-9">
                                                                            <div class="row">                                                                                
                                                                                <div class="mt-3" [ngClass]="{'col-md-6':this.showNomineeForm == true,'col-md-12':this.showNomineeForm == false}">
                                                                                    <label for="name" class="required">{{this.showNomineeForm == false ? 'Company Name' :'First Name'}}</label>
                                                                                    <input type="text" class="form-control" name="firstName" [readonly]="this.companyLogoId == '3' || status == 5 ?'readonly' : ''"
                                                                                        (keypress)="alphabetOnly($event)" formControlName="firstName"
                                                                                        placeholder="{{this.showNomineeForm == false ? 'Company Name' :'First Name'}}"
                                                                                        [ngClass]="{'border border-danger': ((userFormControl['firstName'].touched  || submitted) && !userFormControl['firstName'].valid)}">
                                                                                    <Span class="text-danger"
                                                                                        *ngIf="((userFormControl['firstName'].touched  || submitted) && !userFormControl['firstName'].valid)">
                                                                                        {{this.showNomineeForm == false ? 'Company name' :'First name'}} is required.
                                                                                    </Span>
                                                                                </div>
                                                                                <div class="mt-3" [ngClass]="{'col-md-6':this.showNomineeForm == true,'d-none':this.showNomineeForm == false}">
                                                                                    <label for="name" class="required">Last Name</label>
                                                                                    <input type="text" class="form-control" name="lastName" [readonly]="this.companyLogoId == '3' || status == 5 ?'readonly' : ''"
                                                                                        (keypress)="alphabetOnly($event)" formControlName="lastName"
                                                                                        placeholder="Last name"
                                                                                        [ngClass]="{'border border-danger': ((userFormControl['lastName'].touched  || submitted) && userFormControl['lastName'].errors?.['required'])}">
                                                                                    <Span class="text-danger"
                                                                                        *ngIf="((userFormControl['lastName'].touched  || submitted) && !userFormControl['lastName'].valid)">
                                                                                        Last name is required.
                                                                                    </Span>
                                                                                </div>
                                                                            </div>                
                                                                        </div> 
                                                                        <div class="mt-3 col-md-6" *ngIf="showNomineeForm == false">
                                                                            <label class="required" for="dob">Company GSTIN Number </label>
                                                                            <input type="text" [readOnly]="this.status == 5 ? 'readonly' : ''" placeholder="Eg. 22AAAAA0000A1Z5"
                                                                                formControlName="companyGSTIN" class="new-user form-control bg-transparent"
                                                                                [ngClass]="{'border border-danger': ((userFormControl['companyGSTIN'].touched  || submitted) && !userFormControl['companyGSTIN'].valid)}" />
                                                                            <Span class="text-danger"
                                                                                *ngIf="((userFormControl['companyGSTIN'].touched  || submitted) && userFormControl['companyGSTIN'].errors?.['required'])">
                                                                                Company GSTIN is required.
                                                                            </Span>
                                                                            <Span class="text-danger"
                                                                                *ngIf="((userFormControl['companyGSTIN'].touched  || submitted) && userFormControl['companyGSTIN'].errors?.['pattern'])">
                                                                                GSTIN is not valid.
                                                                            </Span>
                                                                        </div>   
                                                                        <div class="col-12" *ngIf="this.showNomineeForm == true && showPANForm16 == true">
                                                                            <div class="row">     
                                                                                <div class="mt-3 col-md-6">
                                                                                    <fieldset [disabled]="this.status == 5">
                                                                                        <label for="maritalStatus" class="required">Marital Status</label>
                                                                                        <select name="maritalStatus" id="maritalStatus" class="form-select form-select-custom"
                                                                                        formControlName="maritalStatus">
                                                                                            <option *ngFor="let item of maritalStatusList" [value]="item">
                                                                                                {{item}}
                                                                                            </option>
                                                                                        </select>  
                                                                                    </fieldset>
                                                                                </div>                                                                           
                                                                                <div class="mt-3 col-md-6">
                                                                                    <label for="fatherFirstName" class="required">{{userFormControl['maritalStatus'].value == "Single" ? "Father" : "Spouse"}} name</label>
                                                                                    <input type="text" class="form-control" name="fatherFirstName" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                                                        (keypress)="alphabetOnly($event)" formControlName="fatherFirstName"
                                                                                        [placeholder]="userFormControl['maritalStatus'].value == 'Single' ? 'Father name' : 'Spouse name'"
                                                                                        [ngClass]="{'border border-danger': ((userFormControl['fatherFirstName'].touched  || submitted) && !userFormControl['fatherFirstName'].valid)}">
                                                                                    <Span class="text-danger"
                                                                                        *ngIf="((userFormControl['fatherFirstName'].touched  || submitted) && !userFormControl['fatherFirstName'].valid)">
                                                                                        {{userFormControl['maritalStatus'].value == "Single" ? "Father" : "Spouse"}} name is required.
                                                                                    </Span>
                                                                                </div>
                                                                                <!-- <div class="mt-3 col-md-6">
                                                                                    <label for="fatherLastName" class="required">Father Last Name</label>
                                                                                    <input type="text" class="form-control" name="fatherLastName" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                                                        (keypress)="alphabetOnly($event)" formControlName="fatherLastName"
                                                                                        placeholder="Father last name"
                                                                                        [ngClass]="{'border border-danger': ((userFormControl['fatherLastName'].touched  || submitted) && userFormControl['fatherLastName'].errors?.['required'])}">
                                                                                    <Span class="text-danger"
                                                                                        *ngIf="((userFormControl['fatherLastName'].touched  || submitted) && !userFormControl['fatherLastName'].valid)">
                                                                                        Father last name is required.
                                                                                    </Span>
                                                                                </div> -->
                                                                            </div>                
                                                                        </div>                                                                     
                                                                      </div>
                                                                    </div>
                                                                    <div class="col-md-6 d-flex align-items-center justify-content-center">
                                                                        <div class="row justify-content-between w-100">
                                                                            <div class="col-md-6 col-6 d-flex align-items-center mt-3 mt-md-0" *ngIf="showPANForm16 && selectedOptionTab == 'pan'">
                                                                                <label for="pan-file-upload" class="custom-file-upload text-center" >
                                                                                    <img src="{{!panImage ? '../../../assets/svgIcon/uplod.svg' : this.panDocType == 'PDF' ? '../../../assets/svgIcon/pdf_alt.svg' : panImage}}" alt=""
                                                                                        width="160px" height="100px" [ngClass]="{'success':panImage,'required_' : ((userFormControl['panBase64'].touched  || submitted) && userFormControl['panBase64'].errors?.['required'])}">
                                                                                    <p *ngIf="!panImage" class="my-1 px-0 text-grey4">Upload PAN Card Image/PDF</p>
                                                                                    <input id="pan-file-upload" type="file" name="uploadPAN"  [disabled]="this.status == 5" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                                                    (change)="onPanSelected($event)" accept=".png, .pdf" class="d-none">
                                                                                    <p *ngIf="panImage" class="my-1 px-0 text-success">PAN card {{panDocType}} uploaded</p>
                                                                                    <p class="my-1 px-0 text-danger" *ngIf="((userFormControl['panBase64'].touched  || submitted) && userFormControl['panBase64'].errors?.['required'])">
                                                                                        PAN card Image/PDF is required.
                                                                                    </p>
                                                                                </label>  
                                                                            </div>
                                                                            <div class="col-md-6 col-6 d-flex align-items-center  mt-3 mt-md-0" *ngIf="showPANForm16 && selectedOptionTab == 'form16' && showForm16">
                                                                                <label for="form16-file-upload" class="custom-file-upload text-center" >
                                                                                    <img src="{{!form16Image ? '../../../assets/svgIcon/uplod.svg' : this.form16DocType == 'PDF' ? '../../../assets/svgIcon/pdf_alt.svg' : form16Image}}" alt=""
                                                                                        width="160px" height="100px" [ngClass]="{'success':form16Image, 'required_':((userFormControl['form16Base64'].touched  || submitted) && userFormControl['form16Base64'].errors?.['required']) }">
                                                                                    <input id="form16-file-upload" type="file" name="uploadform16"  [disabled]="this.status == 5" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                                                    (change)="onForm16Selected($event)" accept=".png, .pdf" class="d-none">
                                                                                    <p *ngIf="!form16Image" class="my-1 px-0 text-grey4">Upload Form-60 Image/PDF</p>
                                                                                    <p *ngIf="form16Image" class="my-1 px-0 text-success">Form-60 {{form16DocType}} uploaded</p>
                                                                                    <p class="my-1 px-0 text-danger" *ngIf="((userFormControl['form16Base64'].touched  || submitted) && userFormControl['form16Base64'].errors?.['required'])">
                                                                                        Form-60 Image/PDF is required.
                                                                                    </p>
                                                                                </label> 
                                                                            </div>
                                                                            <div class="col-md-6 col-6 d-flex align-items-center mt-3 mt-md-0" *ngIf="showInsuredImage">
                                                                                <label for="insured-file-upload" class="custom-file-upload text-center" >
                                                                                    <img src="{{!insuredImage ? '../../../assets/svgIcon/uplod.svg' : this.insurerDocType == 'PDF' ? '../../../assets/svgIcon/pdf_alt.svg' : insuredImage}}" alt=""
                                                                                        width="160px" height="100px" [ngClass]="{'success':insuredImage,'required_' : ((userFormControl['insuredBase64'].touched  || submitted) && userFormControl['insuredBase64'].errors?.['required'])}">
                                                                                    <p *ngIf="!insuredImage" class="my-1 px-0 text-grey4">Upload Proposer Picture Image/PDF</p>
                                                                                    <input id="insured-file-upload" type="file" name="uploadInsured" [disabled]="this.status == 5" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                                                    (change)="onInsuredImageSelected($event)" accept=".png, .pdf" class="d-none">
                                                                                    <p *ngIf="insuredImage" class="my-1 px-0 text-success">Proposer Picture {{insurerDocType}} uploaded</p>
                                                                                    <p class="my-1 px-0 text-danger" *ngIf="((userFormControl['insuredBase64'].touched  || submitted) && userFormControl['insuredBase64'].errors?.['required'])">
                                                                                        Proposer Picture Image/PDF is required.
                                                                                    </p>
                                                                                </label>  
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                            </div>                                                               
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="justify-content-between row">    
                                                    <div class="col-12 py-3 border-top border-bottom" *ngIf="showAadhar">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <label for="">Aadhar Card Number</label><span class="required"></span>
                                                                <input type="text" name="aadharNumber" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                                    class="form-control" placeholder="Eg : 1234 1234 1234"
                                                                    formControlName="aadharNumber" [(ngModel)]="aadharNumber"
                                                                    [ngClass]="{'border border-danger': ((userFormControl['aadharNumber'].touched  || submitted) && (userFormControl['aadharNumber'].errors?.['required'] || userFormControl['aadharNumber'].errors?.['minlength'] || (aadharMsg != '' && aadharCode != 0 && aadharCode != 200)))}"
                                                                    onkeypress="if(this.value.length=='14') return false;" (keypress)="numberOnly($event)" (input)="formatInput($event)" minlength="14" >
                                                                    <div>
                                                                        <button type="button" class="btn bt-rd btn-primary validate-btn"
                                                                            (click)="adhaarValidate(aadharNumber)" [disabled]="isAadharVerified()">Validate
                                                                        </button>
                                                                    </div>
                                                                <Span class="text-danger" *ngIf="((userFormControl['aadharNumber'].touched  || submitted) && userFormControl['aadharNumber'].errors?.['required'])">
                                                                    Aadhar number is required.
                                                                </Span>
                                                                <Span class="text-danger" *ngIf="((userFormControl['aadharNumber'].touched  || submitted) && userFormControl['aadharNumber'].errors?.['minlength'])">
                                                                    Provide valid Aadhar number.
                                                                </Span>
                                                                <Span [ngClass]="aadharCode != 200 ? 'text-danger':'text-success'" *ngIf="((userFormControl['aadharNumber'].touched  || submitted) && !userFormControl['aadharNumber'].errors?.['required']) && aadharMsg != '' && aadharCode != 0">
                                                                    {{aadharMsg}}
                                                                </Span>
                                                                <Span [ngClass]="'text-danger'" *ngIf="((userFormControl['aadharNumber'].touched  || submitted) && userFormControl['aadharNumber'].value.length == 14) && aadharMsg == '' && aadharCode == 0">
                                                                    Please verify your aadhar number
                                                                </Span>

                                                                <!-- <div *ngIf="button1">
                                                                    <button type="button" class="btn bt-rd btn-primary verified"
                                                                        disabled>Verified</button>
                                                                </div> -->
                                                            </div>
                                                            <div class="col-md-3 col-6 d-flex align-items-center mt-3 mt-md-0">
                                                                <label for="aadaar-front-file-upload" class="custom-file-upload text-center" >
                                                                    <img src="{{!aadharFrontImage ? '../../../assets/svgIcon/uplod.svg' : this.aadhar1DocType == 'PDF'  ? '../../../assets/svgIcon/pdf_alt.svg' : aadharFrontImage}}" alt=""
                                                                        width="160px" height="100px" [ngClass]="{'success':aadharFrontImage, 'required_' : ((userFormControl['aadharFrontBase64'].touched  || submitted) && userFormControl['aadharFrontBase64'].errors?.['required'])}">                                                                            
                                                                    <input id="aadaar-front-file-upload" type="file" name="uploadAadharFrn"  [disabled]="this.status == 5" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                                    (change)="onFrontSelected($event)" accept=".png, .pdf" class="d-none">
                                                                    <p *ngIf="!aadharFrontImage" class="my-1 px-0 text-grey4">Upload Aadhar Image/PDF</p>
                                                                    <p *ngIf="aadharFrontImage" class="my-1 px-0 text-success">Aadhar front {{aadhar1DocType}} uploaded</p>                                                                            
                                                                    <p class="my-1 px-0 text-danger" *ngIf="((userFormControl['aadharFrontBase64'].touched  || submitted) && userFormControl['aadharFrontBase64'].errors?.['required'])">
                                                                        Aadhar Image/PDF is required.
                                                                    </p>
                                                                </label>
                                                            </div>
                                                            <!-- <div class="col-md-3 col-6 d-flex align-items-center mt-3 mt-md-0">
                                                                <label for="aadaar-back-file-upload" class="custom-file-upload text-center">
                                                                    <img src="{{!aadharBackImage ? '../../../assets/svgIcon/uplod.svg' : this.aadhar2DocType == 'PDF'  ? '../../../assets/svgIcon/pdf_alt.svg' : aadharBackImage}}" alt=""
                                                                        width="160px" height="100px"[ngClass]="{'success':aadharBackImage, 'required_' : ((userFormControl['aadharBackbase64'].touched  || submitted) && userFormControl['aadharBackbase64'].errors?.['required'])}">                                                                            
                                                                    <input id="aadaar-back-file-upload" type="file" name="uploadAadharBack"  [disabled]="this.status == 5" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                                    (change)="onBackSelected($event)" accept=".png, .pdf" class="d-none">
                                                                    <p *ngIf="!aadharBackImage" class="my-1 px-0 text-grey4">Upload Aadhar Back Image/PDF</p>
                                                                    <p *ngIf="aadharBackImage" class="my-1 px-0 text-success" >Aadhar back {{aadhar2DocType}} uploaded</p>
                                                                    <p class="my-1 px-0 text-danger" *ngIf="((userFormControl['aadharBackbase64'].touched  || submitted) && userFormControl['aadharBackbase64'].errors?.['required'])">
                                                                        Aadhar back Image/PDF is required.
                                                                    </p>
                                                                </label>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="mt-3" [ngClass]="{'d-none':this.showNomineeForm == false,'col-md-4':this.showNomineeForm == true}">
                                                        <label for="name" class="required">Gender</label>
                                                        <select name="gender" id="gender" class="form-select form-select-custom"
                                                        formControlName="gender">
                                                            <option *ngFor="let gender of genderList" [value]="gender">
                                                                {{gender}}
                                                            </option>
                                                        </select>    
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['gender'].touched  || submitted) && userFormControl['gender'].errors?.['required'])">
                                                            Gender is required.
                                                        </Span>
                                                    </div> 
                                                    <div class="mt-3" [ngClass]="{'col-md-6':this.showNomineeForm == false,'col-md-4':this.showNomineeForm == true}">
                                                        <label for="name" class="required">Email Address</label>
                                                        <input type="text" class="form-control" name="email" formControlName="email"
                                                            placeholder="User email address"
                                                            [ngClass]="{'border border-danger': ((userFormControl['email'].touched  || submitted) && !userFormControl['email'].valid)}">
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['email'].touched  || submitted) && userFormControl['email'].errors?.['required'])">
                                                            Email address is required.
                                                        </Span>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['email'].touched  || submitted) && userFormControl['email'].errors?.['pattern'])">
                                                            Please provide valid email address.
                                                        </Span>
                                                    </div>
                                                    <div class="mt-3" [ngClass]="{'col-md-6':this.showNomineeForm == false,'col-md-4':this.showNomineeForm == true}">
                                                        <label for="name" class="required">Mobile Number</label>
                                                        <input type="text" class="form-control" name="mobile"
                                                            formControlName="phoneNumber" placeholder="User mobile number"
                                                            minlength="10" maxlength="10" (keypress)="numberOnly($event)"
                                                            [ngClass]="{'border border-danger': ((userFormControl['phoneNumber'].touched  || submitted) && !userFormControl['phoneNumber'].valid)}">
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['phoneNumber'].touched  || submitted) && userFormControl['phoneNumber'].errors?.['required'])">
                                                            Mobile number is required.
                                                        </Span>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['phoneNumber'].touched  || submitted) && userFormControl['phoneNumber'].errors?.['pattern'])">
                                                            Provide valid Mobile number.
                                                        </Span>
                                                    </div>                                                                
                                                </div>
                                            </form>
                                        </div>                                   
                            </div>
                            <div class="row">
                                <div class="col-md-12 mt-5  text-end">
                                    <button class="btn btn-primary" (click)="Details('address')">Next</button>
                                </div>
                            </div>
                            </ng-container>
                            </div>
                            <div id="address" class="tab-pane col-12 c-pl c-pr" [ngClass]="{'active': detail == 'address'}">
                            <ng-container >
                                <div class="card p-5 row br-top-0">
                                        <div class="details_card">
                                            <form id="kt_account_profile_details_form" class="form" [formGroup]="userForm"
                                                novalidate="novalidate">
                                                <div class="justify-content-between row">
                                                    <div class="mt-3 col-md-6">
                                                        <label class="required" for="flatNo">Flat Number/Street Number</label>
                                                        <input type="text" class="form-control" name="flatNo" formControlName="flatNo"
                                                            placeholder="Flat Number/Street Number"
                                                            [ngClass]="{'border border-danger': ((userFormControl['flatNo'].touched  || submittedAddress) && userFormControl['flatNo'].errors?.['required'])}">
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['flatNo'].touched  || submittedAddress) && userFormControl['flatNo'].errors?.['required'])">
                                                            Flat number/Street number is required.
                                                        </Span>
                                                    </div>
                                                    <div class="mt-3 col-md-6">
                                                        <label class="required" for="streetAddress">Street Address</label>
                                                        <input type="text" class="form-control" name="streetAddress"
                                                            formControlName="streetAddress" placeholder="Street Address"
                                                            [ngClass]="{'border border-danger': ((userFormControl['streetAddress'].touched  || submittedAddress) && !userFormControl['streetAddress'].valid)}">
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['streetAddress'].touched  || submittedAddress) && userFormControl['streetAddress'].errors?.['required'])">
                                                            Street address is required.
                                                        </Span>
                                                    </div>
                                                    <div class="mt-3 col-md-6">
                                                        <label class="required" for="name">Pincode</label>
                                                        <app-autocomplete class="w-100" (itemSelected)="onPincodeSelection($event)"
                                                            [items]="pincodeList" placeholder="Pincode" [supportingText]="true"
                                                            [userInput]="userFormControl['pincode'].value"
                                                            [showError]="((userFormControl['pincode'].touched  || submittedAddress) && !userFormControl['pincode'].valid)"
                                                             (onKeyUpEvent)="onPincodeChange($event)"  (input)="onInputChange($event)"></app-autocomplete>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['pincode'].touched  || submittedAddress) && userFormControl['pincode'].errors?.['required'])">
                                                            Pincode is required.
                                                        </Span>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['pincode'].touched  || submittedAddress) && userFormControl['pincode'].errors?.['pattern'])">
                                                            Provide valid pincode.
                                                        </Span>
                                                    </div>
                                                    <div class="mt-3 col-md-6">
                                                        <label class="required" for="State">State</label>
                                                        <app-autocomplete class="w-100" [items]="stateList" placeholder="State"
                                                            (itemSelected)="onStateSelection($event)"
                                                            (onBlurEvent)="onStateBlur($event)"
                                                            [userInput]="userFormControl['state'].value"
                                                            [showError]="((userFormControl['state'].touched  || submittedAddress) && !userFormControl['state'].valid)" [readonly]="'readonly'"></app-autocomplete>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['state'].touched  || submittedAddress) && userFormControl['state'].errors?.['required'])">
                                                            State is required.
                                                        </Span>
                                                    </div>
                                                    <div class="mt-3 col-md-6">
                                                        <label for="name">District</label>
                                                        <app-autocomplete class="w-100" [items]="districtList" placeholder="District" [readonly]="'readonly'"
                                                            (itemSelected)="onDistrictSelection($event)"
                                                            (onBlurEvent)="onDistrictBlur($event)"
                                                            [userInput]="userFormControl['district'].value"
                                                            [showError]="((userFormControl['district'].touched  || submittedAddress) && !userFormControl['district'].valid)"></app-autocomplete>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['district'].touched  || submittedAddress) && userFormControl['district'].errors?.['required'])">
                                                            State is required.
                                                        </Span>
                                                    </div>
                                                    <div class="mt-3 col-md-6">
                                                        <label class="required" for="name">City/Talluka/Village</label>
                                                        <app-autocomplete class="w-100" (itemSelected)="onCitySelection($event)"
                                                            [items]="cityList" placeholder="City/Talluka/Village"
                                                            [supportingText]="true"
                                                            [userInput]="userFormControl['city'].value"
                                                            [showError]="((userFormControl['city'].touched  || submittedAddress) && !userFormControl['city'].valid)"
                                                            (onBlurEvent)="onCityBlur($event)"></app-autocomplete>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['city'].touched  || submittedAddress) && userFormControl['city'].errors?.['required'])">
                                                            City/Talluka/Village is required.
                                                        </Span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        
                                    <!-- </div> -->
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-6 mt-5  text-start">
                                    <button class="btn btn-primary-outline btn-outline" (click)="Details('owner')">Back</button>
                                </div>
                                <div class="col-md-6 mt-5 col-6 text-end">
                                    <button class="btn btn-primary" (click)="Details('nominee')" *ngIf="showNomineeForm == true">Next</button>
                                    <button class="btn btn-primary" (click)="Details('vehicle')" *ngIf="showNomineeForm == false">Next</button>
                                </div>
                            </div>
                            </ng-container>
                        </div>
                        <div id="nominee" class="tab-pane col-12 c-pl c-pr" [ngClass]="{'active': detail == 'nominee'}" *ngIf="showNomineeForm==true">
                            <ng-container >
                            <div class="card p-5 row br-top-0">
                                <div class="details_card">
                                    <form class="form needs-validation" [formGroup]="nomineeForm" novalidate="">
                                        <div class="justify-content-between row">
                                            <div class="col-6 mt-3">
                                                <label for="name" [ngClass]="{'required':isNomineeFormRequired == true}">Nominee Name</label>
                                                <input type="text" class="form-control" name="nominee"
                                                    formControlName="nomineeName" placeholder="User nominee name"
                                                    (keypress)="alphabetOnly($event)"
                                                    [ngClass]="{'border border-danger': ((nomineeFormControl['nomineeName'].touched  || submittedNominee) && nomineeFormControl['nomineeName'].errors?.['required'])}" />
                                                <Span class="text-danger"
                                                    *ngIf="((nomineeFormControl['nomineeName'].touched  || submittedNominee) && !nomineeFormControl['nomineeName'].valid)">
                                                    Nominee name is required.
                                                </Span>
                                            </div>
                                            <div class="col-6 mt-3">
                                                <label class="name" [ngClass]="{'required':isNomineeFormRequired == true}">Relation : </label>
                                                <select name="income" id="Relationship" class="form-select form-select-custom"
                                                    formControlName="relationshipWithNominee"
                                                    [ngClass]="{'border border-danger': ((nomineeFormControl['relationshipWithNominee'].touched  || submittedNominee) && nomineeFormControl['relationshipWithNominee'].errors?.['required'])}">
                                                    <option value="" selected>Select Relationship</option>
                                                    <option *ngFor="let relation of RelationShipList" [value]="relation.name">
                                                        {{relation.name}}
                                                    </option>
                                                </select>
                                                <Span class="text-danger"
                                                    *ngIf="((nomineeFormControl['relationshipWithNominee'].touched  || submittedNominee) && !nomineeFormControl['relationshipWithNominee'].valid)">
                                                    Nominee relation is required.
                                                </Span>
                                            </div>
                                            <div class="col-6 mt-3">
                                                <label class="name" [ngClass]="{'required':isNomineeFormRequired == true}">Age : </label>
                                                <select name="Age" placeholder="Select nominee age" formControlName="nomineeAge"
                                                    class="form-select form-select-custom" 
                                                    [ngClass]="{'border border-danger': ((nomineeFormControl['nomineeAge'].touched  || submittedNominee) && !nomineeFormControl['nomineeAge'].valid)}">
                                                    <option *ngFor="let age of ageList" [value]="age.value">
                                                        {{age.text}}
                                                    </option>
                                                </select>
                                                <Span class="text-danger"
                                                    *ngIf="((nomineeFormControl['nomineeAge'].touched  || submittedNominee) && nomineeFormControl['nomineeAge'].errors?.['required'])">
                                                    Nominee age is required.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((nomineeFormControl['nomineeAge'].touched  || submittedNominee) && nomineeFormControl['nomineeAge'].errors?.['pattern'])">
                                                    Provide valid Nominee age.
                                                </Span>
                                            </div>
                                            <div class="col-6 mt-3">
                                                <label [ngClass]="{'required':isNomineeFormRequired == true}" for="gender">Gender</label><br>
                                                <input class="my-3 radio-button scale-1_5 mx-1" type="radio" id="male"
                                                    formControlName="gender" name="gender" value="Male"> Male
                                                &nbsp;&nbsp;
                                                <input class="my-3 radio-button scale-1_5 mx-1" type="radio" id="female"
                                                    formControlName="gender" name="gender" value="Female">
                                                Female &nbsp;&nbsp;
                                                <input class="my-3 radio-button scale-1_5 mx-1" type="radio" id="other"
                                                    formControlName="gender" name="gender" value="Other">
                                                
                                                Other 
                                                <br>
                                                <Span class="text-danger"
                                                *ngIf="((nomineeFormControl['gender'].touched  || submittedNominee) && nomineeFormControl['gender'].invalid)">
                                                Select your Gender
                                            </Span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                
                            </div>
                            <div class="row">
                                <div class="col-md-6 mt-5 col-6  text-start">
                                    <button class="btn btn-primary-outline  btn-outline" (click)="Details('address')">Back</button>
                                </div>
                                <div class="col-md-6 mt-5 col-6 text-end">
                                    <button class="btn btn-primary" (click)="Details('vehicle')">Next</button>
                                </div>
                            </div>
                            </ng-container>
                        </div>
                        <div id="vehicle" class="tab-pane col-12 c-pl c-pr" [ngClass]="{'active': detail == 'vehicle'}">
                            <ng-container >
                            <div class="card p-5 row br-top-0">
                                <form id="kt_vehicle_details_form" class="form" [formGroup]="vehicleForm"
                                novalidate="novalidate">
                                <div class="justify-content-between row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-md-6 mt-3">
                                                <label for="RegistrationNumber" class=" required">Registration Number
                                                </label>
                                                <div class="d-flex">
                                                    <input _ngcontent-jxy-c96=""
                                                        class="mb-0 rounded form-control w-auto pr-0 rounded-right1-0 rto-number" type="text"
                                                        readonly="readonly"
                                                        formControlName="rto">
                                                    <input type="text" name="registrationNumber1"                 
                                                    (keyup)="formatRegNo($event)" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                    (input)="checkRegistrationNumber($event)"
                                                    (paste)="checkRegistrationNumber($event)"
                                                    formControlName="registrationNumber1" placeholder="Registration number"
                                                    maxlength="14" minlength="3" class="form-control rounded-right1-0 pl-60"             
                                                    [ngClass]="{'border border-danger': ((vehicleFormControl['registrationNumber1'].touched  || submittedVehicle) && !vehicleFormControl['registrationNumber1'].valid)}">
                                                    <button  disabled="" class="btn btn-primary py-0 px-2 rounded-left1-0" [disabled]="!vehicleFormControl['registrationNumber'].valid" (click)="getVehicleDetails()">
                                                        <img  src="../../assets/svgIcon/fetch.svg" alt="Fetch" class="icon-white"  title="Fetch Details">
                                                    </button>
                                                </div>                                            
                                                <Span class="text-danger"
                                                    *ngIf="((vehicleFormControl['registrationNumber1'].touched  || submittedVehicle) && vehicleFormControl['registrationNumber1'].errors?.['required'])">
                                                    Registration number is required.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((vehicleFormControl['registrationNumber1'].touched  || submittedVehicle) && vehicleFormControl['registrationNumber1'].errors?.['pattern'])">
                                                    Please provide valid Registration number.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((vehicleFormControl['registrationNumber1'].touched  || submittedVehicle) && vehicleFormControl['registrationNumber1'].errors?.['minlength'])">
                                                    Registration number minimum length is 7 char.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((vehicleFormControl['registrationNumber1'].touched  || submittedVehicle) && vehicleFormControl['registrationNumber1'].errors?.['maxlength'])">
                                                    Registration number maximum length is 10 char.
                                                </Span>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="engineNumber" class=" required">Engine Number </label>
                                                <input type="text" name="engineNumber" formControlName="engineNumber" minlength="5" onkeypress="if(this.value.length=='17') return false" 
                                                    placeholder="Engine number " class="form-control" oninput="this.value = this.value.toUpperCase()"[readOnly]="this.status == 5 ? 'readonly' : ''"
                                                    [ngClass]="{'border border-danger': ((vehicleFormControl['engineNumber'].touched  || submittedVehicle) && !vehicleFormControl['engineNumber'].valid)}" (keypress)="alphaNumericOnly($event)">
                                                <Span class="text-danger"
                                                    *ngIf="((vehicleFormControl['engineNumber'].touched  || submittedVehicle) && vehicleFormControl['engineNumber'].errors?.['required'])">
                                                    Engine number is required.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((vehicleFormControl['engineNumber'].touched  || submittedVehicle) && vehicleFormControl['engineNumber'].errors?.['minlength'])">
                                                    Engine number minimum length is 6 characters.
                                                </Span>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="ChassisNumber" class=" required">Chassis Number </label>
                                                <input type="text" name="ChassisNumber" formControlName="chasisNumber" [minlength]="this.vehicleData.isNew ? 17 : 6"
                                                    placeholder="Chassis number " class="form-control" oninput="this.value = this.value.toUpperCase()"[readOnly]="this.status == 5 ? 'readonly' : ''"
                                                    [ngClass]="{'border border-danger': ((vehicleFormControl['chasisNumber'].touched  || submittedVehicle) && !vehicleFormControl['chasisNumber'].valid)}"  onkeypress="if(this.value.length=='17') return false"  (keypress)="alphaNumericOnly($event)">
                                                <Span class="text-danger"
                                                    *ngIf="((vehicleFormControl['chasisNumber'].touched  || submittedVehicle) && vehicleFormControl['chasisNumber'].errors?.['required'])">
                                                    Chassis number is required.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((vehicleFormControl['chasisNumber'].touched  || submittedVehicle) && vehicleFormControl['chasisNumber'].errors?.['minlength'])">
                                                    Chassis number minimum length is {{this.vehicleData.isNew ? '17' : '6'}} characters.
                                                </Span>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="VehicleRegistrationDate" class="required">Vehicle Registration Date </label>
                                                <input type="date" name="VehicleRegistrationDate" onkeydown="return false" 
                                                    formControlName="registrationDate"  [max]="maxDate" [min]="minDate"
                                                    placeholder="Vehicle Registration Date " class="form-control" [readOnly]="this.status == 5 ? 'readonly' : ''"
                                                    [ngClass]="{'border border-danger': ((vehicleFormControl['registrationDate'].touched  || submittedVehicle) && !vehicleFormControl['registrationDate'].valid)}">
                                                <Span class="text-danger"
                                                    *ngIf="((vehicleFormControl['registrationDate'].touched  || submittedVehicle) && vehicleFormControl['registrationDate'].errors?.['required'])">
                                                    Vehicle Registration Date is required
                                                </Span>
                                            </div>
                                            <div class="col-md-6 mt-3" *ngIf="this.insuranceformData.insuranceCompany==7 && this.insuranceformData.vehicleType == 3 && this.vehiclePremiumData.vehicleData.category == 'PCV' && this.vehiclePremiumData?.vehicleData?.pcVehicle?.toLowerCase() != 'bus' && this.vehiclePremiumData?.vehicleData?.pcVehicle?.toLowerCase() != 'school bus'">
                                                <label for="vehicleUsage" class="required">Vehicle Usage Type</label>
                                                <select name="vehicleUsage" id="vehicleUsage" class="form-select form-select-custom"
                                                    formControlName="usesType" >
                                                    <option value="6">
                                                        Kali-Pili
                                                    </option>
                                                    <option value="4">
                                                        Others
                                                    </option>
                                                </select>  
                                                <Span class="text-danger"
                                                *ngIf="((vehicleFormControl['usesType'].touched  || submittedVehicle) && vehicleFormControl['usesType'].errors?.['required'])">
                                                Vehicle usage type is required
                                            </Span>              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mt-5 col-6  text-start">
                                    <button class="btn btn-primary-outline btn-outline" (click)="Details('nominee')" *ngIf="showNomineeForm == true">Back</button>
                                    <button class="btn btn-primary-outline btn-outline" (click)="Details('address')" *ngIf="showNomineeForm == false">Back</button>
                                </div>
                                <div class="col-md-6 mt-5 col-6 text-end">
                                    <button class="btn btn-primary" (click)="Details('policy')" *ngIf="showExistingPolicyControls || showTPDetailsForm">Next</button>
                                    <button class="btn btn-primary" (click)="continue()" *ngIf="!showExistingPolicyControls && !showTPDetailsForm">Continue</button>
                                </div>
                            </div>
                            </ng-container>
                        </div>
                        <div id="policy" class="tab-pane col-12 c-pl c-pr" [ngClass]="{'active': detail == 'policy'}"  >
                            <ng-container >
                            <div class="card p-5 row br-top-0">
                                <ng-container *ngIf="showExistingPolicyControls">
                                    <form id="kt_vehicle_details_form" class="form pb-4" [formGroup]="vehicleForm"
                                    novalidate="novalidate">
                                    <div class="justify-content-between row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-md-6 mt-3">
                                                    <label for="previousPolicyCompany" class=" required">Existing Policy Company
                                                    </label>
                                                    <app-autocomplete class="w-100" [items]="insurancePartnersList" placeholder="Type and search Current policy company"
                                                        (itemSelected)="onPrevCompSelection($event)"
                                                        (onBlurEvent)="onPrevCompBlur($event)"
                                                        [userInput]="vehicleFormControl['previousPolicyCompany'].value" 
                                                        [showError]="((vehicleFormControl['previousPolicyCompany'].touched  || submittedPolicy) && vehicleFormControl['previousPolicyCompany'].errors?.['required'])"></app-autocomplete>
                                                    <Span class="text-danger"
                                                        *ngIf="((vehicleFormControl['previousPolicyCompany'].touched  || submittedPolicy) && vehicleFormControl['previousPolicyCompany'].errors?.['required'])">
                                                        Existing policy company is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="previousPolicyType" class=" required">Existing Policy Type
                                                    </label>
                                                    <select name="previousPolicyType" formControlName="previousPolicyType"
                                                        placeholder="Existing Policy Type"
                                                        class="form-select form-select-custom"
                                                        [ngClass]="{'border border-danger': ((vehicleFormControl['previousPolicyType'].touched  || submittedPolicy) && !vehicleFormControl['previousPolicyType'].valid)}">
                                                        <option *ngFor="let polType of policyTypeList" [value]="polType.value">
                                                            {{polType.text}}
                                                        </option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((vehicleFormControl['previousPolicyType'].touched  || submittedPolicy) && vehicleFormControl['previousPolicyType'].errors?.['required'])">
                                                        Existing policy type is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="previousPolicyNumber" class=" required">Existing Policy Number
                                                    </label>
                                                    <input type="text" name="previousPolicyNumber"
                                                        formControlName="previousPolicyNumber"
                                                        placeholder="Existing Policy Number" class="form-control" oninput="this.value = this.value.toUpperCase()"
                                                        [ngClass]="{'border border-danger': ((vehicleFormControl['previousPolicyNumber'].touched  || submittedPolicy) && !vehicleFormControl['previousPolicyNumber'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((vehicleFormControl['previousPolicyNumber'].touched  || submittedPolicy) && vehicleFormControl['previousPolicyNumber'].errors?.['required'])">
                                                        Existing policy number is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="previousPolicyExpiryDate" class=" required">Existing Policy Exp.
                                                        Date </label>
                                                    <input type="date" name="previousPolicyExpiryDate"
                                                        formControlName="previousPolicyExpiryDate"
                                                        placeholder="Existing Policy Exp. Date" class="form-control"
                                                        [ngClass]="{'border border-danger': ((vehicleFormControl['previousPolicyExpiryDate'].touched  || submittedPolicy) && !vehicleFormControl['previousPolicyExpiryDate'].valid)}"
                                                        [min]="minPolicyExpiryDate" [max]="maxPolicyExpiryDate" onkeydown="return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((vehicleFormControl['previousPolicyExpiryDate'].touched  || submittedPolicy) && vehicleFormControl['previousPolicyExpiryDate'].errors?.['required'])">
                                                        Existing policy exp. date is required.
                                                    </Span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    </form>  
                                </ng-container>
                                <ng-container *ngIf="showTPDetailsForm">
                                    <hr class="premium_breakup my-0 px-0">
                                    <div _ngcontent-ybu-c94="" class="col-md-12 d-flex justify-content-between align-items-center my-3">
                                        <h4  class="mb-0" *ngIf="plan == 'Standalone'">Existing Third Party Policy Details</h4>
                                        <h4  class="mb-0" *ngIf="plan != 'Standalone'">Existing PA Cover Policy Details </h4>
                                        <!-- <span class="d-flex">
                                            <input type="checkbox" class="scale-1_3 mx-2 my-0" (click)="sameAsPolicyDetails($event)">
                                            <label for="" class="mb-0">Same as above</label>
                                        </span> -->
                                    </div>
                                    <hr class="premium_breakup my-0 px-0">
                                    <form id="kt_vehicle_details_form pt-2" class="form" [formGroup]="existingTPPolicyDetailsForm"
                                    novalidate="novalidate">
                                    <div class="justify-content-between row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-md-6 mt-3">
                                                    <label for="tpPolicyNumber" class=" required">
                                                        Is Standalone PA Cover Policy?                                                            
                                                    </label>
                                                    <span class="d-flex align-items-center justify-content-between">
                                                        <span class="d-flex align-items-center">
                                                         <input type="radio" class="scale-1_3 ml-0 mr-2 mt-0"  [value]="'Yes'" [name]="'tpIsStandalone'" formControlName="tpIsStandalone"><span>Yes</span>
                                                         <input type="radio" class="scale-1_3 mx-2 mt-0" [value]="'No'" [name]="'tpIsStandalone'" formControlName="tpIsStandalone"><span>No</span>
                                                        </span>                      
                                                     </span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="tpInsurer" class=" required">
                                                        {{(plan == 'Standalone' ) ? "Existing Third Party Company" :"Existing PA Cover Company" }}
                                                    </label>
                                                    <app-autocomplete class="w-100" [items]="insurancePartnersList" placeholder="Type and search Existing policy company"
                                                        (itemSelected)="onPrevTPCompSelection($event)"
                                                        (onBlurEvent)="onPrevTPCompBlur($event)"
                                                        [userInput]="existingTPPolicyDetailsFormControl['tpInsurer'].value" 
                                                        [showError]="((existingTPPolicyDetailsFormControl['tpInsurer'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpInsurer'].errors?.['required'])"></app-autocomplete>
                                                    <Span class="text-danger"
                                                        *ngIf="((existingTPPolicyDetailsFormControl['tpInsurer'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpInsurer'].errors?.['required'])">
                                                        {{(plan == 'Standalone' ) ? "Existing third party company is required." :"Existing PA Cover company is required." }}
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="tpPolicyNumber" class=" required">
                                                        {{(plan == 'Standalone' ) ? "Existing Third Party Policy Number" :"Existing PA Cover Policy Number" }}
                                                        
                                                    </label>
                                                    <input type="text" name="tpPolicyNumber"
                                                        formControlName="tpPolicyNumber"
                                                        placeholder="Existing Policy Number" class="form-control"
                                                        [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpPolicyNumber'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpPolicyNumber'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((existingTPPolicyDetailsFormControl['tpPolicyNumber'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpPolicyNumber'].errors?.['required'])">
                                                        {{(plan == 'Standalone' ) ? "Existing third party policy number is required." :"Existing PA Cover policy number is required." }}
                                                        
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="tpPolicyTerm" class=" required">
                                                        {{(plan == 'Standalone' ) ? "Existing Third Party Policy Tenure" :"Existing PA Cover Policy Tenure" }}
                                                    </label>
                                                    <select name="tpPolicyTerm" formControlName="tpPolicyTerm"
                                                        placeholder="Existing Third Party Policy Tenure"
                                                        class="form-select form-select-custom"
                                                        [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpPolicyTerm'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpPolicyTerm'].valid)}">
                                                        <option *ngFor="let polType of policyTermList" [value]="polType.value">
                                                            {{polType.text}}
                                                        </option>
                                                    </select>  
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="previousPolicyNumber" class=" required">
                                                        {{(plan == 'Standalone' ) ? "Existing Third Party Policy Start Date" :"Existing PA Cover Policy Start Date" }}
                                                        </label>
                                                    <input type="date" name="tpInceptionDate"
                                                        formControlName="tpInceptionDate"
                                                        placeholder="{{plan == 'Standalone'  ? 'Existing Third Party Policy Start Date' :'Existing PA Cover Policy Start Date' }}" class="form-control"
                                                        [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpInceptionDate'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpInceptionDate'].valid)}"
                                                        [min]="minStartTPPolicyExpiryDate" [max]="maxStartTPPolicyExpiryDate" onkeydown="return false" (change)="tpStartDateChange()">
                                                    <Span class="text-danger"
                                                        *ngIf="((existingTPPolicyDetailsFormControl['tpInceptionDate'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpInceptionDate'].errors?.['required'])">
                                                        {{(plan == 'Standalone' ) ? "Existing third party policy start date is required." :"Existing PA Cover policy start date is required." }}
                                                        
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="tpExpiryDate" class=" required">
                                                        {{(plan == 'Standalone' ) ? "Existing Third Party Policy Exp. Date" :"Existing PA Cover Policy Exp. Date" }}
                                                        </label>
                                                    <input type="date" name="tpExpiryDate"
                                                        formControlName="tpExpiryDate"
                                                        placeholder="{{(plan == 'Standalone' ) ? 'Existing Third Party Policy Exp. Date' :'Existing PA Cover Policy Exp. Date' }}" class="form-control"
                                                        [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpExpiryDate'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpExpiryDate'].valid)}"
                                                        [min]="minTPPolicyExpiryDate" [max]="maxTPPolicyExpiryDate" onkeydown="return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((existingTPPolicyDetailsFormControl['tpExpiryDate'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpExpiryDate'].errors?.['required'])">
                                                        {{(plan == 'Standalone' ) ? "Existing third party policy exp. date is required." :"Existing PA Cover policy exp. date is required." }}
                                                        
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="tpInsurerAddress" class=" required">
                                                        Ensuring Branch Address
                                                    </label>
                                                    <input type="text" name="tpInsurerAddress"
                                                        formControlName="tpInsurerAddress"
                                                        placeholder="Ensuring Branch Address" class="form-control"
                                                        [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpInsurerAddress'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpInsurerAddress'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((existingTPPolicyDetailsFormControl['tpInsurerAddress'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpInsurerAddress'].errors?.['required'])">
                                                        Ensuring branch address is required.
                                                    </Span>
                                                </div>                                        
                                                <div class="col-md-6 mt-3">
                                                    <label for="tpAddress2" class=" required">Ensuring Branch State
                                                    </label>
                                                    <app-autocomplete class="w-100" [items]="ensuringBranchStateList" placeholder="Ensuring Branch State"
                                                        (itemSelected)="onEnsuringBranchStateSelection($event)"
                                                        (onBlurEvent)="onEnsuringBranchStateBlur($event)"
                                                        [userInput]="existingTPPolicyDetailsFormControl['tpAddress2'].value"
                                                        [showError]="((existingTPPolicyDetailsFormControl['tpAddress2'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpAddress2'].valid)"></app-autocomplete>
                                                    <Span class="text-danger"
                                                        *ngIf="((existingTPPolicyDetailsFormControl['tpAddress2'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpAddress2'].errors?.['required'])">
                                                        Ensuring branch state is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="tpAddress1" class=" required">Ensuring Branch City
                                                    </label>
                                                    <app-autocomplete class="w-100" [items]="ensuringBranchDistrictList" placeholder="Ensuring Branch City"
                                                        (itemSelected)="onEnsuringBranchDistrictSelection($event)"
                                                        (onBlurEvent)="onEnsuringBranchDistrictBlur($event)"
                                                        [userInput]="existingTPPolicyDetailsFormControl['tpAddress1'].value"
                                                        [showError]="((existingTPPolicyDetailsFormControl['tpAddress1'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpAddress1'].valid)"></app-autocomplete>
                                                    <Span class="text-danger"
                                                        *ngIf="((existingTPPolicyDetailsFormControl['tpAddress1'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpAddress1'].errors?.['required'])">
                                                        Ensuring branch city is required.
                                                    </Span>
                                                </div>                                                
                                                <div class="col-md-6 mt-3">
                                                    <label for="tpSumInsured" class=" required">Existing PA Cover Sum Insured
                                                    </label>
                                                    <input type="text" name="tpSumInsured"
                                                        formControlName="tpSumInsured" (keypress)="numberOnly($event)"
                                                        placeholder="Ensuring Branch Address" class="form-control"
                                                        [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpSumInsured'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpSumInsured'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((existingTPPolicyDetailsFormControl['tpSumInsured'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpSumInsured'].errors?.['required'])">
                                                        PA cover sum insured is required.
                                                    </Span> 
                                                    <Span class="text-danger"
                                                        *ngIf="((existingTPPolicyDetailsFormControl['tpSumInsured'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpSumInsured'].value != '' && !isPASumInsuredGT15Lacs())">
                                                        PA cover sum insured must be 1500000 or above.
                                                    </Span> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </form>
                                </ng-container>                           

                            </div>
                            <div class="row">
                                <div class="col-md-6 mt-5 col-6 text-start">
                                    <button class="btn btn-primary-outline btn-outline" (click)="Details('vehicle')">Back</button>
                                </div>
                                <div class="col-md-6 mt-5 col-6 text-end">
                                    <button class="btn btn-primary" (click)="continue()">Continue</button>
                                </div>
                            </div>
                            </ng-container>
                        </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isSummry">
                        <div class="summary-view">
                            <div class="col-12 px-0 shadow_ rounded1 card">
                            <div class="collapsible-panel rounded-top1">
                                <div class="panel-header rounded-top1" (click)="toggleCollapse(1)">
                                    <span class="d-flex align-items-center">Owner Details
                                        <span class="text-main d-flex font-14 align-items-center" (click)="editClick('owner')">
                                            <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1"> Edit
                                        </span> 
                                    </span> 
                                <span class="collapse-icon"> <img src="../../assets/svgIcon/{{ panel1 ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                                </div>
                                <div class="panel-content" [ngClass]="{ 'expanded': panel1,'collapsed': !panel1 }">
                                    <div class="row">
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Name</label>
                                                <span>{{userFormControl['title'].value}} {{userFormControl['firstName'].value}} {{userFormControl['lastName'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-label">Date Of Birth</label>
                                                <span>{{userFormControl['dateOfBirth'].value| date: 'dd MMM, yyyy'}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Mobile Number</label>
                                                <span>{{userFormControl['phoneNumber'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Email Id</label>
                                                <span>{{userFormControl['email'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">PAN Card Number</label>
                                                <span>{{userFormControl['panNumber'].value | uppercase}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6" *ngIf="showAadhar">
                                            <div class="form-group">
                                                <label class="form-label">Aadhar Card Number</label>
                                                <span>{{userFormControl['aadharNumber'].value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row border-top text-center" *ngIf="showPANForm16 || showAadhar">    
                                        <div class="col-md-3 col-6" *ngIf="showPANForm16 && selectedOptionTab == 'form16'">
                                            <div class="form-group">
                                                <label class="form-label w-100">Form-16 {{form16DocType}}</label>
                                                <img src="{{this.form16DocType == 'PDF' ? '../../../assets/svgIcon/pdf_alt.svg':form16Image}}" alt="Form-16 {{form16DocType}}" width="160px" height="100px">
                                            </div>
                                        </div> 
                                        <div class="col-md-3 col-6" *ngIf="showPANForm16 && selectedOptionTab == 'pan'">
                                            <div class="form-group">
                                                <label class="form-label w-100">PAN card {{panDocType}}</label>
                                                <img src="{{this.panDocType == 'PDF' ? '../../../assets/svgIcon/pdf_alt.svg' : panImage}}" alt="PAN card {{panDocType}}" width="160px" height="100px">
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-6" *ngIf="showInsuredImage">
                                            <div class="form-group">
                                                <label class="form-label w-100">Proposer Picture {{insurerDocType}}</label>
                                                <img src="{{this.insurerDocType == 'PDF' ? '../../../assets/svgIcon/pdf_alt.svg':insuredImage}}" alt="Proposer Picture {{insurerDocType}}" width="160px" height="100px">
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-6" *ngIf="showAadhar">
                                            <div class="form-group">
                                                <label class="form-label w-100">Aadhar {{aadhar1DocType}}</label>
                                                <img src="{{this.aadhar1DocType == 'PDF' ? '../../../assets/svgIcon/pdf_alt.svg':aadharFrontImage}}" alt="Aadhar {{aadhar1DocType}}" width="160px" height="100px">
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-3 col-6" *ngIf="showAadhar">
                                            <div class="form-group">
                                                <label class="form-label w-100">Aadhar back side {{this.aadhar2DocType}}</label>
                                                <img src="{{this.aadhar2DocType == 'PDF' ? '../../../assets/svgIcon/pdf_alt.svg':aadharBackImage}}" alt="Aadhar back side {{aadhar2DocType}}" width="160px" height="100px">
                                            </div>
                                        </div>                                    -->
                                    </div>
                                </div>
                            </div>            
                            <div class="collapsible-panel">
                                <div class="panel-header" (click)="toggleCollapse(2)">
                                   <span class="d-flex align-items-center">Address Details 
                                        <span class="text-main d-flex font-14 align-items-center" (click)="editClick('address')">
                                            <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1"> Edit
                                        </span> 
                                    </span> 
                                <span class="collapse-icon"><img src="../../assets/svgIcon/{{ panel2 ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                                </div>
                                <div class="panel-content" [ngClass]="{ 'expanded': panel2,'collapsed': !panel2 }">
                                    <div class="row">
                                        <div class="col-md-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Flat Number</label>
                                                <span>{{userFormControl['flatNo'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Street Address</label>
                                                <span>{{userFormControl['streetAddress'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">State</label>
                                                <span>{{userFormControl['state'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">District</label>
                                                <span>{{userFormControl['district'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">City/Talluka/Village</label>
                                                <span>{{userFormControl['city'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-label">PIN Code</label>
                                                <span>{{userFormControl['pincode'].value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>            
                            <div class="collapsible-panel" *ngIf="showNomineeForm == true">
                                <div class="panel-header" (click)="toggleCollapse(3)">   
                                    <span class="d-flex align-items-center">Nominee Details
                                        <span class="text-main d-flex font-14 align-items-center" (click)="editClick('nominee')">
                                            <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1"> Edit
                                        </span> 
                                    </span> 
                                    <span class="collapse-icon"><img src="../../assets/svgIcon/{{ panel3 ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                                </div>
                                <div class="panel-content" [ngClass]="{ 'expanded': panel3,'collapsed': !panel3 }">
                                    <div class="row">
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Nominee's Name</label>
                                                <span>{{nomineeFormControl['nomineeName'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Relation</label>
                                                <span>{{nomineeFormControl['relationshipWithNominee'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-label">Age</label>
                                                <span>{{nomineeFormControl['nomineeAge'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-label">Gender</label>
                                                <span>{{nomineeFormControl['gender'].value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>            
                            <div class="collapsible-panel">
                                <div class="panel-header" (click)="toggleCollapse(4)">
                                    <span class="d-flex align-items-center">Vehicle Details
                                        <span class="text-main d-flex font-14 align-items-center" (click)="editClick('vehicle')">
                                            <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1"> Edit
                                        </span> 
                                    </span>
                                    <span class="collapse-icon"><img src="../../assets/svgIcon/{{ panel4 ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                                </div>
                                <div class="panel-content" [ngClass]="{ 'expanded': panel4,'collapsed': !panel4 }">
                                    <div class="row">
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Registration Number</label>
                                                <span>{{vehicleFormControl['registrationNumber'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Engine Number</label>
                                                <span>{{vehicleFormControl['engineNumber'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-label">Chassis Number</label>
                                                <span>{{vehicleFormControl['chasisNumber'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-label">Vehicle Registration Date</label>
                                                <span>{{vehicleFormControl['registrationDate'].value | date: 'dd MMM, yyyy'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>            
                            <div class="collapsible-panel rounded-bottom1" *ngIf="showExistingPolicyControls || showTPDetailsForm">
                                <div class="panel-header" (click)="toggleCollapse(5)">                                    
                                    <span class="d-flex align-items-center">Existing Policy Details
                                        <span class="text-main d-flex font-14 align-items-center" (click)="editClick('policy')">
                                            <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1"> Edit
                                        </span> 
                                    </span>
                                    <span class="collapse-icon"><img src="../../assets/svgIcon/{{ panel5 ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                                </div>
                                <div class="panel-content rounded-bottom1" [ngClass]="{ 'expanded': panel5,'collapsed': !panel5 }">
                                    <div class="row" *ngIf="showExistingPolicyControls">
                                        <div class="col-md-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Existing Policy Company</label>
                                                <span>{{vehicleFormControl['previousPolicyCompany'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Existing Policy Type</label>
                                                <span>{{vehicleFormControl['previousPolicyType'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-label">Existing Policy Number</label>
                                                <span>{{vehicleFormControl['previousPolicyNumber'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-label">Existing Policy Expiry Date</label>
                                                <span>{{vehicleFormControl['previousPolicyExpiryDate'].value | date: 'dd MMM, yyyy'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div _ngcontent-ybu-c94="" class="row my-3" *ngIf="showTPDetailsForm">
                                        <div _ngcontent-ybu-c94="" class="col-md-12 d-flex justify-content-between align-items-center">
                                            <h4  class="mb-0" *ngIf="plan == 'Standalone'">Existing Third Party Policy Details</h4>
                                            <h4  class="mb-0" *ngIf="plan != 'Standalone'">Existing PA Cover Policy Details </h4>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="showTPDetailsForm">
                                        <div class="col-md-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Is Standalone PA Cover Policy?</label>
                                                <span>{{existingTPPolicyDetailsFormControl['tpIsStandalone'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">  {{(plan == 'Standalone' ) ? "Existing Third Party Company" :"Existing PA Cover Company" }}</label>
                                                <span>{{existingTPPolicyDetailsFormControl['tpInsurer'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label"> {{(plan == 'Standalone' ) ? "Existing Third Party Policy Number" :"Existing PA Cover Policy Number" }}</label>
                                                <span>{{existingTPPolicyDetailsFormControl['tpPolicyNumber'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-label">{{(plan == 'Standalone' ) ? "Existing Third Party Policy Tenure" :"Existing PA Cover Policy Tenure" }}</label>
                                                <span>{{existingTPPolicyDetailsFormControl['tpPolicyTerm'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-label">{{(plan == 'Standalone' ) ? "Existing Third Party Policy Start Date" :"Existing PA Cover Policy Start Date" }}</label>
                                                <span>{{existingTPPolicyDetailsFormControl['tpInceptionDate'].value| date: 'dd MMM, yyyy'}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-label">{{(plan == 'Standalone' ) ? "Existing Third Party Policy Exp. Date" :"Existing PA Cover Policy Exp. Date" }}</label>
                                                <span>{{existingTPPolicyDetailsFormControl['tpExpiryDate'].value| date: 'dd MMM, yyyy'}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Ensuring Branch Address</label>
                                                <span>{{existingTPPolicyDetailsFormControl['tpInsurerAddress'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 border-bottom">
                                            <div class="form-group">
                                                <label class="form-label">Ensuring Branch City</label>
                                                <span>{{existingTPPolicyDetailsFormControl['tpAddress1'].value}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-label">Ensuring Branch State</label>
                                                <span>{{existingTPPolicyDetailsFormControl['tpAddress2'].value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="bottm_bar pt-5 fixed">
        <div class=" container">
            <div class="row">
                <div class="col-md-6">
                    <p class="font-body mb-0">Net premium</p>
                    <h1 class="text-start mb-0">₹{{premiumData.netPremium}}</h1>
                </div>
                <div class="col-md-6">
                    <div class="row justify-content-end">
                        <div class="justify-content-end d-flex align-items-center"
                            [ngClass]="{'col-4': !isSmallScreen, 'col-12': isSmallScreen}">
                            <a href="/faqs" class="btn">Help?</a>
                        </div>
                        <div class="btn-continue" [ngClass]="{'col-4': !isSmallScreen, 'col-12': isSmallScreen}">
                            <button class="btn btn-primary" (click)="continue()">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="insuranceChild bottomFooter">
    <div class="row childBottomFooter">
      <div class="col-md-6">
        <div style=" padding-left: 21px;">Net Premium</div>
        <div class="coverageAmount">₹{{premium}}</div>
      </div>
      <div class="col-md-4">
        <button class="floatRight btn btn-primary continueBtn" (click)="continue()">Continue</button>
        <div class="floatRight help">Help?</div>
      </div>
    </div>
  </div> -->
  </div>

  <div *ngIf="isRoyalSundaram">
    <app-royal-sundaram-payment [royalDetails] = "royalDetails"></app-royal-sundaram-payment>
  </div>
  <div *ngIf="isFutureGeneral">
    <app-future-general-payment [futureDetails] = "futureDetails"></app-future-general-payment>
  </div>
  
  <div *ngIf="isShriramGeneral">
    <app-shriramgeneral-payment [ShriramDetails] = "ShriramDetails"></app-shriramgeneral-payment>
  </div>
  
  <div *ngIf="isRelianceGeneral">
    <app-reliance-general-payment [RelianceDetails] = "RelianceDetails"></app-reliance-general-payment>
  </div>
  
  <div *ngIf="isHDFC">
    <app-hdfcergo-payment [HDFCDetails] = "HDFCDetails"></app-hdfcergo-payment>
  </div>
  <div *ngIf="isPaymentLink">
    <app-payment-progress [paymentObject]="paymentObject"></app-payment-progress>
  </div>
  <div class="overlay" *ngIf="showKYCPopup">
    <div class="popup2 position-relative col-md-11 col-11 height90">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Complete KYC</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeKYCModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative height90">
        <iframe id="ifr2" [src]="kycUrl" target="_parent" frameborder='0' class="iframeclass"></iframe>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showhttpKYCPopup">
    <div class="popup2 position-relative col-md-11 col-11 height90">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Complete KYC</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closehttpKYCModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative height90">

        <span class="font-17-13 text-grey5 d-block mt-2">Please use below link to update your KYC details</span>
        <br />
        <a id="failedKYCLink" target="_blank" (click)="closehttpKYCModal()" [href]="kycUrl">Click here to update your
          KYC details</a>
        <br />
        <span class="font-17-13 text-grey5 d-block mt-2">Once your KYC is done, return to this window and click on the
          pay button</span>

      </div>
    </div>
  </div>


  <div class="overlay" *ngIf="showValidatePolicyUserPopup">
    <div class="popup2 col-md-4 col-11 login-bg">
      <div class="modal-header tooltip_ pb-0 bg-transparent">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeValidatePolicyUserPopup()">
        </button>
      </div>
      <div class="modal-body pt-0">
        <div class="row">
            <div class="col-md-12 text-center mb-4">
              <img src="../../assets/images/logo.svg" alt="" class="logo">
            </div>
          </div>
        <form id="kt_account_profile_details_form" class="form" [formGroup]="validatePolicyUserForm" novalidate="novalidate">
		<div class="text-center mb-3">
		  <h2 class="text-center">OTP <span class="h"> Verification</span></h2>
		  <p class="text-center text-grey5">Enter 6 digit code that sent to mobile number 
			+91{{this.validatePolicyUserFormControl['phoneNumber'].value }} and email address {{this.validatePolicyUserFormControl['emailAddress'].value}}.</p>
		</div>
		<div class="form-group mb-2">
		  <label class="input-label required lbl" for="mobile">Enter OTP</label>
		  <input type="number" class="form-control" name="phone" formControlName="otpNumber"
			placeholder="Enter OTP" minlength="6" maxlength="6"
			onfocus="this.placeholder=''"
			[ngClass]="{'border border-danger': ((validatePolicyUserFormControl['otpNumber'].touched || submittedOtp) && !validatePolicyUserFormControl['otpNumber'].valid)}" (keydown)="validateNumberInput($event)"  (input)="onInput($event)">
		  <Span class="text-danger"
			*ngIf="((validatePolicyUserFormControl['otpNumber'].touched || submittedOtp) && validatePolicyUserFormControl['otpNumber'].errors?.['required'])">
			OTP is required.
		  </Span>
		  <Span class="text-danger"
			*ngIf="((validatePolicyUserFormControl['otpNumber'].touched || submittedOtp) && validatePolicyUserFormControl['otpNumber'].errors?.['pattern'])">
			Provide valid OTP.
		  </Span>
		</div>
		<div class="passwords_div min-height50">
		  
			<div class="row"><div class="col-md-6">
			  <span>
				<ng-container *ngIf="timerValue > 0">Resend OTP in 00: {{ timerValue > 9 ? timerValue : '0'+ timerValue }}
				  Secs</ng-container></span>
			</div>
		  <div class="col-md-6 text-end">
			<button type="button" *ngIf="timerValue == 0" [disabled]="timerValue > 0"
			  class="link-primary forgot_password_link cursor-pointer btn-none px-0"
			  (click)="sendOTP(true)"> Resend OTP </button>
		  </div>
        </div>
		</div>
		<div class="d-grid mt-3">
		  <button class="btn btn-primary login_btn" type="button" (click)="validateOtp()"
			[disabled]="!validatePolicyUserFormControl['otpNumber'].valid">
			<span class="indicator-label">Verify</span>
		  </button>
		</div>
        </form>
		</div>
	</div>
   </div>
   <div class="overlay" *ngIf="showIsBreakIn">
    <div class="popup2 position-relative col-md-4 col-11 ">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4 class="mb-0">Vehicle inspection required</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeBreakInModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative text-center">
        <span class="font-17-13 text-grey5 d-block mt-2">You will need to upload a short video of your vehicle. It takes only a few minutes. Do you want to Continue?</span>
        <div class="row mt-8 justify-content-around">
            <button type="button" class="btn btn-primary col-auto" aria-label="Close" (click)="validateUserByOTP()">
                Yes Continue
              </button>
              <button type="button" class="btn btn-secondary col-auto" aria-label="Close" (click)="closeBreakInModal()">
                No
              </button>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showIsBreakInTriggered">
    <div class="popup2 position-relative col-md-8 col-11 ">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4 class="mb-0">Inspection Request Submitted</h4>
        </div>
        <!-- <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeBreakInModal()">
          <span aria-hidden="true"></span>
        </button> -->
      </div>
      <div class="modal-body position-relative">
        <div class="row">
            <div class="col-md-4 d-flex align-item-center">
                <img src="../../../assets/svgIcon/list_clipboard.svg" class="img-fluid">
            </div>
            <div class="col-md-8 d-flex align-item-center">
                <div class="row">
                    <div class="col-md-12 text-main font-15-12">
                        Please complete the inspection process by clicking on the link  you received in your phone.
                        After completing this process, it will take approximate 48 hours to verify your vehicle’s inspection details.
                        Please use {{this.insuranceformData.quoteId??""}} - your reference ID for the update. However, we will notify you once inspection is verified.
                    </div>
                    <div class="col-md-12 mt-5 text-main font-15-12">
                        <span class="d-block">You can continue your policy journey with the following steps:</span>
                        <span class="d-block"><span class="text-main2">Step 1: </span>Login with your credentials.</span>
                        <span class="d-block"><span class="text-main2">Step 2: </span>Go to Dashboard.</span>
                        <span class="d-block"><span class="text-main2">Step 3: </span>Click on Incomplete tab available in Policies menu option.</span>
                        <span class="d-block"><span class="text-main2">Step 4: </span>Select your policy and click on Continue button.</span>
                        <span class="d-block"><span class="text-main2">Step 5: </span>Go to payment, and complete the transaction.</span>
                        <span class="d-block"><span class="text-main2">Step 6: </span>Congratulations! Policy is issued for your vehicle.</span>
                    </div>
                    <div class="col-12">
                        <button type="button" class="btn btn-primary col-md-6" aria-label="Close" (click)="goToPolicyDetails()">
                            Ok
                          </button>
                    </div>
                </div>    
            </div>
        </div>
      </div>
    </div>
  </div>

  </div>
  </div>
