import { Component, Input, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { InsuranceService } from '../../Services/insurance.service';
import { ReviewvehicledetailsComponent } from '../../reviewvehicledetails/reviewvehicledetails.component';
import { VehiclequotesComponent } from '../vehiclequotes/vehiclequotes.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VehicleOwnerDetailsComponent } from '../vehicle-owner-details/vehicle-owner-details.component';
import { VehicleQuoteDetailsComponent } from '../vehicle-quote-details/vehicle-quote-details.component';
import { VehiclePolicyDetailsComponent } from '../vehicle-policy-details/vehicle-policy-details.component';
import { AppComponent } from 'src/app/app.component';
import * as moment from 'moment';
import { InsuranceDetails, SelectedOption } from 'src/app/Models/vehicle-component.model';
import { MenuService } from 'src/app/Services/menu.service';
import { HomeComponent } from 'src/app/home/home.component';
import { VehicleService } from 'src/app/Services/vehicle.service';
import { LookUpService } from 'src/app/Services/lookup.service';
import { AutoCompleteItem } from 'src/app/Models/Item';
import { LanguageLableService } from 'src/app/Services/language-lable.service';
import { CoreModule } from 'src/app/core/core.module';
import { DomSanitizer } from '@angular/platform-browser';
import { GeneralService } from 'src/app/Services/general.service';
@Component({
  selector: 'bike',
  templateUrl: './bike.component.html',
  styleUrls: ['./bike.component.css']
})
export class BikeComponent implements OnInit {
  // @Output() onLabelClick = new EventEmitter<void>();
  @Output() onLabelClick: EventEmitter<any> = new EventEmitter<any>();
  //New function code
  bikeNum: any = true;
  policy: any = false;
  Type: any = false;
  preClaim: any = false;
  isClaimed: any = false;
  claimPercentage: any = false;
  chosenOption: SelectedOption[] = [];// = new SelectedOption();
  isBikeR: boolean = true;
  isProducts: boolean = false;
  policyClaimed: any = 0;
  policyClaimedNCB: any = 100;
  policyExpiryStatus: any = 0;
  policyType: any = 0;
  isFormSubmitted: boolean = false;
  // Vehicle Registration Validation

  donthavepolicyDetails: boolean = false;
  verifyBikeDetails: boolean = false;
  verifyBikeDetailspolicy: boolean = false;
  isDontKnowVehicleNumber: boolean = false;

  isInvalidInput: boolean = false;
  vehicleRegNumber: string = '';
  showViewQuotesButton: boolean = false;
  newVehicle: string;
  vehicleMakeList: AutoCompleteItem[] = [];
  vehicleModelList: AutoCompleteItem[] = [];
  vehicleVariantList: AutoCompleteItem[] = [];
  insurancePartnersList: AutoCompleteItem[] = [];
  showPreviousPolicyCompany: boolean = false;
  showPreviousPolicyCompanyError: boolean = false;
  previousPolicyCompany: string = "";
  selectedValue: string | null;
  langList: any;
  insuranceformData = {} as InsuranceDetails;
  showKYCPopup: boolean = false;
  showhttpKYCPopup: boolean = false;
  kycUrl: any = '';
  minPolicyExpiryDate = moment(new Date()).add(-5, 'years').format('YYYY-MM-DD');
  maxPolicyExpiryDate = moment(new Date()).add(5, 'years').format('YYYY-MM-DD');
  showIsBreakIn:boolean = false;
  showIsBreakInTriggered:boolean = false;
  claimsMadeInPreviousPolicyNo: any = "1";
  images: any[] = [
    './assets/partners/new/digit.png',
    './assets/partners/new/sompo.png',
    './assets/partners/new/shriram.png',
    './assets/partners/new/reliance.png' ,
    './assets/partners/new/icici.png',
    './assets/partners/new/oriental.png',
    './assets/partners/new/bajaj.png',
    './assets/partners/new/national.png'
  ]
  showAllImages = false;
  openItemIndex = -1;
  toggleAnswer(index: number): void {
    if (this.openItemIndex === index) {
      this.openItemIndex = -1; // Clicking the open item again should close it
    } else {
      this.openItemIndex = index; // Open the clicked item
    }
  }
  text = '';
  type = 0;
  getValue(type: number) {
    this.type = type;
    if (type == 1) {
      this.text = 'Covers the full cost of repairs or replacements without accounting for depreciation, ensuring you receive the maximum claim amount.';
    }
    else if (type == 2) {
      this.text = 'Consumable cover reimburses the cost of items like engine oil, nuts, bolts, and other consumables used during bike repairs.';
    }
    else if (type == 3) {
      this.text = 'Return to Invoice cover reimburses the difference between your bike’s insured declared value and its original invoice value in case of total loss or theft.';
    }
    else if (type == 4) {
      this.text = 'Roadside assistance provides emergency support for issues like breakdowns, flat tires, or fuel shortages, ensuring help is available wherever you are.';
    }
    else if (type == 5) {
      this.text = 'Engine and gearbox protection covers repair or replacement costs for damage caused to the engine and transmission system due to issues like water ingress or mechanical failure.';
    }
    else if (type == 6) {
      this.text = 'Daily allowance provides a fixed amount of compensation for each day your bike is under repair at a garage, helping to cover alternative transportation costs.';
    }

  }


  onVehicleRegNumberInput(): void {
    // Check if the input matches the desired format
    const regexPattern = /^[A-Z]{2}-\d{1,2}-[A-Z]{1,3}-\d{1,4}$/;
    this.isInvalidInput = !regexPattern.test(this.vehicleRegNumber);
  }
  // onVehicleRegNumberInput(): void {
  //   // Check if the input has more than 10 characters
  //   if (this.vehicleRegNumber.length > 10) {
  //     this.isInvalidInput = true;
  //   } else {
  //     this.isInvalidInput = false;
  //   }
  //   // const regexPattern = /^[A-Za-z]{2}\d{2}[A-Za-z]{2}\d{4}/;
  //   // this.isInvalidInput = regexPattern.test(this.vehicleRegNumber);
  // }
  // isValidVehicleRegNumber(): boolean {
  //   return this.vehicleRegNumber.length === 10 && /^[0-9]{10}$/.test(this.vehicleRegNumber);
  // }

  async bikeNumber() {
    this.verifyBikeDetails = false;
    this.validateRegistrationNumber(false);
    //this.bikeNum = false;
  }
  continuePolicyDetails() {
    this.isFormSubmitted = true;
    if (this.vehicleForm.valid) {
      this.verifyBikeDetails = false;
      this.verifyBikeDetailspolicy = true;
    }
  }
  novalidpolicy() {
    this.donthavepolicyDetails = true;
  }
  closeModal() {
    this.donthavepolicyDetails = false;
    this.verifyBikeDetails = false;
    this.verifyBikeDetailspolicy = false;
  }
  closeKYCModal() {
    this.showKYCPopup = false;
  }
  closehttpKYCModal() {
    this.showhttpKYCPopup = false;
  }
  // policyExpired(){
  //   this.policy = !this.policy;
  //   this.Type = !this.Type;
  // }

  // policyType(){
  //   this.Type = !this.Type;
  //   this.preClaim = !this.preClaim;
  // }


  preClaimDetail(claimVal: any): void {
    this.vehicleForm.get('claimsMadeInPreviousPolicyNo')?.setValue("1");
    this.claimsMadeInPreviousPolicyNo = "1";
    let index = parseInt(claimVal.target.value);
    if (index == 1) {
      this.isClaimed = true;
      this.vehicleForm.get('claimsMadeInPreviousPolicy')?.setValue("Yes");
    }
    else {
      this.isClaimed = false;
      this.vehicleForm.get('claimsMadeInPreviousPolicy')?.setValue("No");

      if (this.vehicleForm.get('previousPolicyType')?.value == "Comprehensive") {
        this.policyClaimedNCB = 0;
      }
      else if (this.vehicleForm.get('previousPolicyType')?.value == "Thirdparty") {
        this.policyClaimedNCB = 0;
      }
    }
    this.toggleViewQuotesButton();
    this.toggleNCBPercentage();
    console.log('Clicked value:', claimVal);
  }

  policyDetail(polVal: any): void {
    let index = parseInt(polVal.target.value);
    this.toggleViewQuotesButton();
    this.vehicleForm.get('policyExpiryStatus')?.setValue(index.toString());
    if (index == 1) {
      this.vehicleForm.get('isPreviousPolicyHolder')?.setValue("True");
      this.minPolicyExpiryDate = moment(new Date()).format('YYYY-MM-DD');
      this.maxPolicyExpiryDate = moment(new Date()).add(5, 'years').format('YYYY-MM-DD');
      this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(moment(new Date()).format('YYYY-MM-DD'));
    }
    else if (index == 2) {
      this.vehicleForm.get('isPreviousPolicyHolder')?.setValue("False");
      this.minPolicyExpiryDate = moment(new Date()).add(-90, 'days').format('YYYY-MM-DD');
      this.maxPolicyExpiryDate = moment(new Date()).add(-1, 'days').format('YYYY-MM-DD');
      this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(moment(new Date()).add(-1, 'months').format('YYYY-MM-DD'));
      this.insuranceForm.get('previousPolicyExpiryDate')?.setValue(this.vehicleForm.get('previousPolicyExpiryDate')?.value);
    }
    else {
      this.minPolicyExpiryDate = moment(new Date()).add(-5, 'years').format('YYYY-MM-DD');
      this.maxPolicyExpiryDate = moment(new Date()).add(5, 'years').format('YYYY-MM-DD');
      this.vehicleForm.get('isPreviousPolicyHolder')?.setValue("False");
      this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(moment(new Date()).add(-4, 'months').format('YYYY-MM-DD'));
    }
    // this.insuranceForm.get('isPreviousPolicyHolder')?.setValue(this.vehicleForm.get('isPreviousPolicyHolder')?.value);    
    this.insuranceForm.get('previousPolicyExpiryDate')?.setValue(this.vehicleForm.get('previousPolicyExpiryDate')?.value);
    // if(this.chosenOption.length == 0 || ( this.chosenOption.length > 0 && this.chosenOption.filter(f=> f.order ==1 ).length == 0)){
    //   this.chosenOption.push({label:"Policy's Expiry Status", value:polVal, value1:"", order :1});
    // }
    // else
    // {
    //   this.chosenOption = this.chosenOption.map(p =>
    //     p.order === 1
    //       ? { ...p, value: polVal }
    //       : p
    //   );
    // }


    this.toggleNCBPercentage();
    console.log('Clicked value:', polVal);
  }
  coverageDetail(covVal: any): void {
    //this.Type = !this.Type;
    this.preClaim = true;
    //this.setCoverageValue(covVal);
    this.policyClaimedNCB = 100;
    let index = parseInt(covVal.target.value);
    this.vehicleForm.get('claimsMadeInPreviousPolicyNo')?.setValue("1");
    this.claimsMadeInPreviousPolicyNo = "1";
    if (index == 1) {
      this.vehicleForm.get('previousPolicyType')?.setValue("Comprehensive");
      this.vehicleForm.get('typeOfCover')?.setValue("Comprehensive");
      this.vehicleForm.get('ncbprevious')?.setValue("0");
      this.vehicleForm.get('claimsMadeInPreviousPolicy')?.setValue("No");
      this.isClaimed = false;
      this.preClaim = true;
      //this.setnsbValue("20%");
      this.policyClaimed = 2;
      this.policyClaimedNCB = 0;
      //this.showViewQuotesButton = true;
      //this.setPreviousClaimed('No');
      //this.value2=['20%','25%','35%','45%','50%'];
    }
    else if (index == 2) {
      this.vehicleForm.get('previousPolicyType')?.setValue("Thirdparty");
      this.vehicleForm.get('typeOfCover')?.setValue("LiabilityOnly");
      this.vehicleForm.get('ncbprevious')?.setValue("0");
      this.vehicleForm.get('claimsMadeInPreviousPolicy')?.setValue("No");
      this.preClaim = true;
      this.isClaimed = false;
      this.policyClaimed = 2;
      this.policyClaimedNCB = 0;
      //this.setnsbValue("0%");
      //this.showViewQuotesButton = true;
      //this.setPreviousClaimed('No');
      //this.value2=['0%'];
    }
    else {
      this.vehicleForm.get('previousPolicyType')?.setValue("Thirdparty");
      this.vehicleForm.get('typeOfCover')?.setValue("");
      //this.showViewQuotesButton = true;
    }
    this.insuranceForm.get('previousPolicyType')?.setValue(this.vehicleForm.get('previousPolicyType')?.value);
    this.toggleViewQuotesButton();
    this.toggleNCBPercentage();
    console.log('Clicked value:', covVal);
  }

  nsbValDetail(nsbVal: any): void {
    let nbsvalue = nsbVal.target.value.replace(/[^a-zA-Z0-9]/g, '');
    this.vehicleForm.get('ncbprevious')?.setValue(nbsvalue);
    this.policyClaimedNCB = nbsvalue;
    console.log('Clicked value:', nsbVal);
    this.toggleViewQuotesButton();
    //this.setnsbValue(nsbVal);
  }

  // setCoverageValue(covVal:any){
  //   if(this.chosenOption.length == 0 || ( this.chosenOption.length >0 && this.chosenOption.filter(f=> f.order ==2 ).length == 0)){
  //     this.chosenOption.push({label:"Policy Type",value:covVal, value1:"", order :2});
  //   }
  //   else
  //   {
  //     this.chosenOption = this.chosenOption.map(p =>
  //       p.order === 2
  //         ? { ...p, value: covVal }
  //         : p
  //     );
  //   }
  // }

  // setnsbValue(nsbVal:any)
  // {
  //   if(this.chosenOption.length == 0 || (this.chosenOption.length > 0 && this.chosenOption.filter(f=> f.order ==3 ).length == 0)){
  //     this.chosenOption.push({label:"Policy Claimed",value: (this.isClaimed ? "Yes" : "No"), value1:(this.isClaimed ? "" : nsbVal), order :3});
  //   }
  //   else
  //   {
  //     this.chosenOption = this.chosenOption.map(p =>
  //       p.order === 3
  //         ? { ...p, value1: (this.isClaimed ? "" : nsbVal)  }
  //         : p
  //     );
  //   }
  // }

  // setPreviousClaimed(claimVal:any){
  //   if(this.chosenOption.length == 0 || ( this.chosenOption.length >0 && this.chosenOption.filter(f=> f.order ==3 ).length == 0)){
  //     this.chosenOption.push({label:"Policy Claimed", value:claimVal, value1:"", order :3});
  //   }
  //   else
  //   {
  //     this.chosenOption = this.chosenOption.map(p =>
  //       p.order === 3
  //         ? { ...p, value: claimVal }
  //         : p
  //     );
  //   }
  // }

  value = ['No, It Is Still Active', 'Yes, It Has Expired Within 90 Days', 'I am Not Sure', 'Yes, It Has Expired After 90 Days'];
  value1 = ['Standard/Comprehensive Coverage', 'Third-Party Liability Coverage', 'I am Not Sure'];
  value2 = ['0%', '20%', '25%', '35%', '45%', '50%'];
  value3 = ['Yes', 'No'];

  policyExpiryStatusList = [
    { value: 0, text: 'Select Existing Policy Status' },
    { value: 1, text: 'Not Expired' },
    { value: 2, text: 'Expired Within 90 Days' },
    {value:4,text:'Expired More than 90 Days ago'},
    // {value:3,text:"I don't remember"}    
  ];

  policyTypeList =
    [
      { value: 0, text: 'Select Existing Policy Type' },
      { value: 1, text: 'Standard/Comprehensive Coverage' },
      { value: 2, text: 'Third-Party Liability Coverage' },
      { value: 3, text: "I don't remember" }
    ];

  policyClaimedList =
    [
      { value: 0, text: 'Select Existing Policy Claim' },
      { value: 1, text: 'Yes' },
      { value: 2, text: 'No' }
    ];

  policyNcbClaimedList = [
    { value: 100, text: 'Select existing NCB (No Claim Bonus) ' },
    { value: 0, text: '0%' },
    { value: 20, text: '20%' },
    { value: 25, text: '25%' },
    { value: 35, text: '35%' },
    { value: 45, text: '45%' },
    { value: 50, text: '50%' }
  ];
  hoveredItem: any; // Track the hovered item
  hoveredItem1: any; // Track the hovered item

  changeColor(value: any) {
    this.hoveredItem = value;
  }

  resetColor(value: any) {
    this.hoveredItem = null;
  }


  insuranceForm: FormGroup;
  insuranceForm1: FormGroup;
  vehicleForm: FormGroup;
  modalRef: BsModalRef;
  registrationData: any;
  IsQuotes: boolean = false;
  IsProposer: boolean = false;
  IsQuoteSelected: boolean = false;
  IsPolicyDetail: boolean = false;
  vehicleInfo: any;
  IsDontKnowBike: boolean = false;
  subscription: Subscription;
  vehicleRegisterData: any;
  showMenu: boolean = false;
  showNCBTooltip: boolean = false;
  showPolicyStatusTooltip: boolean = false;
  fuelType: any[] = ['Electric', 'Petrol'];
  pincodeList: AutoCompleteItem[] = [];
  @ViewChild(VehiclequotesComponent) VehiclequotesComponent: VehiclequotesComponent;
  @ViewChild(VehicleQuoteDetailsComponent) VehicleQuoteDetailsComponent: VehicleQuoteDetailsComponent;
  @ViewChild(VehicleOwnerDetailsComponent) VehicleOwnerDetailsComponent: VehicleOwnerDetailsComponent;
  @ViewChild(VehiclePolicyDetailsComponent) VehiclePolicyDetailsComponent: VehiclePolicyDetailsComponent;

  constructor(private languageLableService: LanguageLableService, private fb: FormBuilder, private spinner: NgxSpinnerService,
    private insuranceservice: InsuranceService, private modalService: BsModalService,
    private router: Router, public appComponent: AppComponent,// private homeComponent:HomeComponent,//private vehicleOwnerDetailsComponent:VehicleOwnerDetailsComponent,
    private menuService: MenuService, private vehicleService: VehicleService, private _lookupService: LookUpService, private sanitizer: DomSanitizer, private generalService: GeneralService, private lookUpService: LookUpService) {
    this.insuranceForm = this.fb.group({
      registrationNumber: ['', Validators.required],
      policyType: ['', Validators.required],
      policyExpiryStatus: ['', Validators.required],
      policyClaimed: ['', Validators.required],
      policyClaimedNCB: ['', Validators.required],
      claimsMadeInPreviousPolicyNo: ['1', Validators.required],
      VehicleType: 2,
      quoteId: [''],
      isMockUp: [false],
      proposerDetails: [],
      nomineeDetails: [],
      vehicleDetails: [],
      previousPolicyCompany: [''],
      previousPolicyExpiryDate: ['']
    });

    this.vehicleForm = this.fb.group({
      "city": [""],
      "rto": [""],
      "make": ["", Validators.required],
      "model": ["", Validators.required],
      "variant": ["", Validators.required],
      "registrationYear": [""],
      "registrationNumber": [""],
      "chasisNumber": [""],
      "engineNumber": [""],
      "registrationDate": [""],
      "firstName": [""],
      "lastName": [""],
      "vehiclecategory": [""],
      "isPreviousPolicyHolder": ["True"],
      "previousPolicyType": ["Comprehensive"],
      "claimsMadeInPreviousPolicy": [""],
      "claimsMadeInPreviousPolicyNo": ['1'],
      "ncbprevious": [""],
      "typeOfCover": [""],
      "previousPolicyExpiryDate": [""],
      "vehicleIdv": ["0"],
      "previousPolicyCompany": [""],
      "previousPolicyNumber": [""],
      "ncbCurrent": ["0"],
      "fuelType": [""],
      "engineCapacityAmount": [""],
      "PACover": ["Yes"],
      "RegisteredInTheNameOf": ["Individual"],
      "isValidDrivingLicenseAvailable": ["Yes"],
      "isFinanced": ["No"],
      "financedValue": [""],
      "financierName": [""],
      "isNew": [false],
      "pincode": [""],
      "policyExpiryStatus":["1"]
    });

    this.generalService.removeQuoteLocalStorage();
  }
  isUserLogged: boolean = false;
  policyNcbClaimMadeList = [
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    // { value: '4', text: '4' },
    // { value: '5', text: '5' }
  ];
  ngOnInit() {
    this.getValue(1);
    if (localStorage.getItem("selectedValue") != '') {
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else {
      this.selectedValue = "english";
    }
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }
    //if (sessionStorage.getItem('quotes_data') != undefined && sessionStorage.getItem('quotes_data') != '') {
    // let dataQuote =sessionStorage.getItem('quotes_data') as any; 
    // var quote =  JSON.parse(dataQuote);
    // let vehicle =sessionStorage.getItem('vehicle_data') as any; 
    // var vehicleData =  JSON.parse(vehicle);
    // let mockockup = sessionStorage.getItem('IsMockup') as any; 
    // var IsMockup =  JSON.parse(mockockup);
    // quote.grossPremium = quote.grossPremium.replace("INR","").replace(" ","");
    // const premium: number = parseFloat(quote.grossPremium);
    //  const gst: number = parseFloat((premium * 18 / 100).toFixed(2));
    //  const netpremium = parseFloat((premium + gst).toFixed(2));
    //  const vehiclePremiumData = {
    //    "quoteDetails": quote,
    //    "isMockUp": IsMockup,
    //    "vehicleData": vehicleData,
    //    "premiumData":
    //    {
    //      "premium": premium,
    //      "gst": gst,
    //      "netPremium": netpremium
    //    }
    //  };
    //  this. ShowQuoteDetials(vehiclePremiumData);
    //  sessionStorage.removeItem("quotes_data");
    //  sessionStorage.removeItem("vehicle_data");
    //  sessionStorage.removeItem("IsMockup");
    //}
    if (this.generalService.getUniqueuserid() != "") {
      this.isUserLogged = true;
    }
  }

  async VerifyRegistrationNumber() {
    if (this.isDontKnowVehicleNumber == false) {
      this.onLabelClick.emit();
      this.validateRegistrationNumber(true);
    }
    else {
      this.verifyBikeDetailspolicy = false; //new 17-04-2024
      this.policy = false; //new 17-04-2024
      this.Type = false; //new 17-04-2024
      this.preClaim = false;  //new 17-04-2024
      this.IsDontKnowBike = true; //new 17-04-2024
    }
  }

  async validateRegistrationNumber(showDetails: boolean) {
    this.spinner.show();
    await this.insuranceservice.GetKycDetails(this.insuranceForm.value.registrationNumber).then(async (response) => {
      if (response?.isSuccess) {
        if (showDetails) {
          if (this.previousPolicyCompany == "" && (parseInt(this.policyExpiryStatus) > 0 && parseInt(this.policyExpiryStatus) < 3)) {
            this.showPreviousPolicyCompanyError = true;
          }
          else {
            this.verifyBikeDetailspolicy = false; // coom
            this.showPreviousPolicyCompanyError = false;
            this.IsQuotes = true; //com
            setTimeout(async () => await this.getvehiclequotes1(), 500);
          }
        }
        else {
          if (response.result.vehicle_category != "2WN") {
            swal.fire({
              position: 'center',
              imageUrl: "../../assets/alerts/warning.png",
              imageHeight: 89,
              title: 'Please provide valid two-wheeler number.',
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: 'Ok',
              cancelButtonColor: "#DF3A4D",
              customClass: {
                confirmButton: "error_btn",
              },
            });
          }
          else {
            console.log(response.result);
            this.registrationData = response.result;
            this.verifyBikeDetails = true;
            this.policy = true;
            this.Type = true;
            this.preClaim = true;
            this.fillVehicleForm();
            this.vehicleForm.get('make')?.setValue('');
            this.vehicleForm.get('model')?.setValue('');
            this.loadMakeList(false, '', '');
            setTimeout(() => {
              this.loadMakeList(true, this.registrationData.maker_description, this.registrationData.maker_model);
            }, 200);

            this.loadInsurancePartners();
          }
        }
      }
      else {
        // this.verifyBikeDetails = false;
        // this.novalidpolicy();

        await this.insuranceservice.GetKycDetails(this.insuranceForm.value.registrationNumber).then((response) => {
          if (response?.isSuccess) {
            if (showDetails) {
              if (this.previousPolicyCompany == "" && (parseInt(this.policyExpiryStatus) > 0 && parseInt(this.policyExpiryStatus) < 3)) {
                this.showPreviousPolicyCompanyError = true;
              }
              else {
                this.verifyBikeDetailspolicy = false; // coom
                this.showPreviousPolicyCompanyError = false;
                this.IsQuotes = true; //com
                setTimeout(async () => await this.getvehiclequotes1(), 500);
              }
            }
            else {
              if (response.result.vehicle_category != "2WN") {
                swal.fire({
                  position: 'center',
                  imageUrl: "../../assets/alerts/warning.png",
                  imageHeight: 89,
                  title: 'Please provide valid two-wheeler number.',
                  showCancelButton: true,
                  showConfirmButton: false,
                  cancelButtonText: 'Ok',
                  cancelButtonColor: "#DF3A4D",
                  customClass: {
                    confirmButton: "error_btn",
                  },
                });
              }
              else {
                console.log(response.result);
                this.registrationData = response.result;
                this.verifyBikeDetails = true;
                this.policy = true;
                this.Type = true;
                this.preClaim = true;
                this.fillVehicleForm();
                this.vehicleForm.get('make')?.setValue('');
                this.vehicleForm.get('model')?.setValue('');
                this.loadMakeList(false, '', '');
                setTimeout(() => {
                  this.loadMakeList(true, this.registrationData.maker_description, this.registrationData.maker_model);
                }, 200);

                this.loadInsurancePartners();
              }
            }
          }
          else {
            this.verifyBikeDetails = false;
            this.novalidpolicy();
          }
          this.spinner.hide();
        });
      }
      this.spinner.hide();
    });

  }



  openModalWithData(registrationData: any) {

    const initialState: any = {
      list: [
        {
          "registrationData": registrationData,
          "Bike": true
        }
      ]
    };
    this.modalRef = this.modalService.show(ReviewvehicledetailsComponent, {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
      initialState
    });
    this.modalRef.content.dataSubject.subscribe(async (data: any) => {
      this.registrationData = data;
      this.IsQuotes = true;
      setTimeout(async () => await this.getvehiclequotes(), 500);

    });
  }

  async getvehiclequotes() {
    let regNo = this.formatReg(this.registrationData.rc_number);
    let rto = "";
    if(regNo != null && regNo != undefined && regNo != ""){
      rto = regNo.split("-")[0] +"-"+ regNo.split("-")[1] 
    }
    this.vehicleForm.get('registrationNumber')?.setValue(regNo);
    this.vehicleForm.get('chasisNumber')?.setValue(this.registrationData.vehicle_chasi_number);
    this.vehicleForm.get('engineNumber')?.setValue(this.registrationData.vehicle_engine_number);
    this.vehicleForm.get('model')?.setValue(this.registrationData.maker_model);
    this.vehicleForm.get('make')?.setValue(this.registrationData.maker_description);
    this.vehicleForm.get('registrationDate')?.setValue(this.registrationData.registration_date);
    //moment(this.registrationData.registration_date,'YYYY-MM-DD').format('DD/MM/YYYY'));
    this.vehicleForm.get('registrationYear')?.setValue(this.registrationData.registration_date.split('-')[0]);
    this.vehicleForm.get('rto')?.setValue(rto);
    this.vehicleForm.get('city')?.setValue(`${this.registrationData.registered_at.split(' ')[0]}`);
    this.vehicleForm.get('firstName')?.setValue(`${this.registrationData.owner_name.split(' ').slice(0, 1).join(' ')}`);
    this.vehicleForm.get('lastName')?.setValue(`${this.registrationData.owner_name.split(' ').slice(1).join(' ')}`);
    this.vehicleForm.get('vehiclecategory')?.setValue(this.registrationData.vehicle_category);
    this.vehicleForm.get('vehicleIdv')?.setValue("0");
    this.vehicleForm.get('previousPolicyCompany')?.setValue(this.registrationData.insurance_company);
    this.vehicleForm.get('previousPolicyNumber')?.setValue(this.registrationData.insurance_policy_number);
    this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(this.registrationData.insurance_upto);
    this.insuranceForm.get('previousPolicyExpiryDate')?.setValue(this.registrationData.insurance_upto);
    let fuel_type = this.registrationData.fuel_type;
    if (fuel_type != null && fuel_type != undefined && fuel_type != "") {
      fuel_type = fuel_type.toLowerCase();
      fuel_type = fuel_type.charAt(0).toUpperCase() + fuel_type.slice(1);
    } 
    this.vehicleForm.get('fuelType')?.setValue(fuel_type);
    //this.vehicleForm.get('fuelType')?.setValue(this.registrationData.fuel_type);
    this.vehicleForm.get('variant')?.setValue(this.registrationData.variant);
    this.vehicleForm.get('engineCapacityAmount')?.setValue(this.registrationData.cubic_capacity);
    this.vehicleForm.get('PACover')?.setValue("Yes");
    this.vehicleForm.get('RegisteredInTheNameOf')?.setValue("Individual");
    this.vehicleForm.get('isValidDrivingLicenseAvailable')?.setValue("Yes");

    //moment(this.registrationData.insurance_upto,'YYYY-MM-DD').format('DD/MM/YYYY'));
    //this.vehicleForm.get('yearOfManufacture')?.setValue(this.registrationData.manufacturing_date.split('/')[1]);
    this.insuranceForm.get('vehicleDetails')?.setValue(this.vehicleForm.value);
    this.VehiclequotesComponent.GetQuoteInfo(this.insuranceForm.value);
  }

  async getvehiclequotes1() {
    this.vehicleForm.get('registrationYear')?.setValue(this.vehicleForm.value.registrationDate.split('-')[0]);
    this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(this.insuranceForm.get('previousPolicyExpiryDate')?.value);
    let regNo = this.formatReg(this.registrationData.rc_number);
    let rto = "";
    if(regNo != null && regNo != undefined && regNo != ""){
      rto = regNo.split("-")[0] +"-"+ regNo.split("-")[1] 
    }
    this.vehicleForm.get('rto')?.setValue(rto);
    this.insuranceForm.get('vehicleDetails')?.setValue(this.vehicleForm.value);

    this.VehiclequotesComponent.GetQuoteInfo(this.insuranceForm.value); //com
    // this.insuranceservice.QuotesValue(this.insuranceForm.value); //new
    // this.router.navigate(['/vehicle-quotes',{q:moment(new Date()).format("YYYYMMDDHHmmssSSSSSS")}]); //new
    //this.VehiclequotesComponent.showMenu = this.showMenu;
  }

  async fillVehicleForm() {

    let regNo = this.formatReg(this.registrationData.rc_number);
    let rto = "";
    if(regNo != null && regNo != undefined && regNo != ""){
      rto = regNo.split("-")[0] +"-"+ regNo.split("-")[1] 
    }

    this.vehicleForm.get('registrationNumber')?.setValue(regNo);
    this.vehicleForm.get('chasisNumber')?.setValue(this.registrationData.vehicle_chasi_number);
    this.vehicleForm.get('engineNumber')?.setValue(this.registrationData.vehicle_engine_number);
    // this.vehicleForm.get('model')?.setValue(this.registrationData.maker_model);
    //this.vehicleForm.get('make')?.setValue(this.registrationData.maker_description);
    this.vehicleForm.get('registrationDate')?.setValue(this.registrationData.registration_date);
    //moment(this.registrationData.registration_date,'YYYY-MM-DD').format('DD/MM/YYYY'));
    this.vehicleForm.get('registrationYear')?.setValue(this.registrationData.registration_date.split('-')[0]);
    this.vehicleForm.get('rto')?.setValue(rto);
    this.vehicleForm.get('city')?.setValue(`${this.registrationData.registered_at.split(' ')[0]}`);
    this.vehicleForm.get('firstName')?.setValue(`${this.registrationData.owner_name.split(' ').slice(0, 1).join(' ')}`);
    this.vehicleForm.get('lastName')?.setValue(`${this.registrationData.owner_name.split(' ').slice(1).join(' ')}`);
    this.vehicleForm.get('vehiclecategory')?.setValue(this.registrationData.vehicle_category);
    this.vehicleForm.get('vehicleIdv')?.setValue("0");
    this.vehicleForm.get('previousPolicyCompany')?.setValue(this.registrationData.insurance_company);
    this.vehicleForm.get('previousPolicyNumber')?.setValue(this.registrationData.insurance_policy_number);
    ;
    this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(this.registrationData.insurance_upto);
    this.insuranceForm.get('previousPolicyExpiryDate')?.setValue(this.registrationData.insurance_upto);
    let fuel_type = this.registrationData.fuel_type;
    if (fuel_type != null && fuel_type != undefined && fuel_type != "") {
      fuel_type = fuel_type.toLowerCase();
      fuel_type = fuel_type.charAt(0).toUpperCase() + fuel_type.slice(1);
    }
    this.vehicleForm.get('fuelType')?.setValue(fuel_type);
    //this.vehicleForm.get('variant')?.setValue(this.registrationData.variant);
    this.vehicleForm.get('engineCapacityAmount')?.setValue(this.registrationData.cubic_capacity);
    this.vehicleForm.get('PACover')?.setValue("Yes");
    this.vehicleForm.get('RegisteredInTheNameOf')?.setValue("Individual");
    this.vehicleForm.get('isValidDrivingLicenseAvailable')?.setValue("Yes");
    //moment(this.registrationData.insurance_upto,'YYYY-MM-DD').format('DD/MM/YYYY'));
    //this.vehicleForm.get('yearOfManufacture')?.setValue(this.registrationData.manufacturing_date.split('/')[1]);
    this.insuranceForm.get('vehicleDetails')?.setValue(this.vehicleForm.value);
    //this.VehiclequotesComponent.GetQuoteInfo(this.insuranceForm.value);
  }

  async DontKnowBike(data: any) {
    data.vehicleDetails.vehiclecategory = "2WN";
    if (data.vehicleDetails.isNew) {
      this.loadForm();
      this.insuranceForm.get('vehicleDetails')?.setValue(data.vehicleDetails);
    }
    else {
      this.vehicleForm.patchValue(data.vehicleDetails);
      this.vehicleForm.get('ncbprevious')?.setValue(this.newVehicle == "1" ? "0" : this.policyClaimedNCB.toString());
      this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(this.insuranceForm.value.previousPolicyExpiryDate);
      this.insuranceForm.get('vehicleDetails')?.setValue(this.vehicleForm.value);
    }
    this.IsQuotes = true; //com
    this.IsDontKnowBike = false;
    this.insuranceForm.value.registrationNumber = null;

    this.vehicleForm.get('isNew')?.setValue(data.vehicleDetails.isNew);
    this.insuranceForm.value.VehicleType = 2;
    if (data.proposerDetails != null && data.proposerDetails != undefined) {
      this.insuranceForm.get('proposerDetails')?.setValue(data.proposerDetails);
    }

    this.onLabelClick.emit();
    //this.insuranceservice.QuotesValue(this.insuranceForm.value);
    //this.router.navigate(['/vehicle-quotes',{q:moment(new Date()).format("YYYYMMDDHHmmssSSSSSS")}]); //new
    let ttt = this.insuranceForm.value;
    this.VehiclequotesComponent.GetQuoteInfo(this.insuranceForm.value);
  }

  loadForm() {
    this.insuranceForm = this.fb.group({
      quoteId: [''],
      isMockUp: [false],
      VehicleType: [2],
      proposerDetails: [],
      nomineeDetails: [],
      vehicleDetails: []

    });
  }

  royalDetails: any;
  isRoyalSundaram: boolean = false;

  futureDetails: any;
  isFutureGeneral: boolean = false;

  ShriramDetails: any;
  isShriramGeneral: boolean = false;

  RelianceDetails: any;
  isRelianceGeneral: boolean = false;
  isPaymentLink:boolean = false;
  paymentObject:any;

  loadVehicleForm() {

    let regNo = this.formatReg(this.vehicleRegisterData.registrationNumber);
    let rto = "";
    if(regNo != null && regNo != undefined && regNo != ""){
      rto = regNo.split("-")[0] +"-"+ regNo.split("-")[1] 
    }

    this.vehicleForm.get('registrationNumber')?.setValue(regNo);
    this.vehicleForm.get('chasisNumber')?.setValue(this.vehicleRegisterData.chasisNumber);
    this.vehicleForm.get('engineNumber')?.setValue(this.vehicleRegisterData.engineNumber);
    this.vehicleForm.get('model')?.setValue(this.vehicleRegisterData.model);
    this.vehicleForm.get('make')?.setValue(this.vehicleRegisterData.make);
    this.vehicleForm.get('registrationDate')?.setValue(this.vehicleRegisterData.registrationDate);
    this.vehicleForm.get('registrationYear')?.setValue(this.vehicleRegisterData.registrationYear);
    this.vehicleForm.get('rto')?.setValue(rto);
    this.vehicleForm.get('city')?.setValue(this.vehicleRegisterData.city);
    this.vehicleForm.get('firstName')?.setValue(this.vehicleRegisterData.firstName);
    this.vehicleForm.get('lastName')?.setValue(this.vehicleRegisterData.lastName);
    this.vehicleForm.get('vehiclecategory')?.setValue(this.vehicleRegisterData.vehiclecategory);
    this.vehicleForm.get('vehicleIdv')?.setValue(this.vehicleRegisterData.vehicleIdv);
    this.vehicleForm.get('previousPolicyCompany')?.setValue(this.vehicleRegisterData.previousPolicyCompany);
    this.vehicleForm.get('previousPolicyNumber')?.setValue(this.vehicleRegisterData.previousPolicyNumber);
    this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(this.vehicleRegisterData.previousPolicyExpiryDate);
    //this.insuranceForm.get('previousPolicyExpiryDate')?.setValue(this.vehicleRegisterData.previousPolicyExpiryDate);
  }


  async CreatePolicy() {
    this.spinner.show();
    this.showIsBreakIn=false;
    if (this.insuranceForm.value.vehicleDetails == null) {
      this.loadVehicleForm();
    }
    this.insuranceForm.value.VehicleType = this.insuranceForm.value.vehicleDetails.vehiclecategory == "2WN" ? 2 : 1;
    const response: any = await this.insuranceservice.CreateQuote(this.insuranceformData);//this.insuranceForm.value);
    if (response?.isSuccess && response?.result?.code == 2) {
      //pre inspection case
        this.showIsBreakIn=true;
        this.insuranceformData.isBreakIn=true;
      }
      else if (response?.isSuccess && response?.result?.code == 3) {
        //after pre inspection api trigger
         this.showIsBreakInTriggered=true;
        //   swal.fire({
        //     imageUrl: "../../assets/alerts/success.png",
        //     imageHeight: 89,
        //     title:'',
        //     text: '',
        //     html: response?.result?.message,
        //     showDenyButton: false,
        //     confirmButtonText: "OK",
        //     confirmButtonColor:"#0DB561",
        //     showCancelButton: false,  
        //     cancelButtonText:"Don't want",    
        //     denyButtonText: "Don't want",
        //     denyButtonColor:"#0DB561",
        //   }).then((result) => {
        //     //this.router.navigate(['policyDetails']);
        //   });
      }
      else if (response?.isSuccess && response?.result?.code == 1) {
      if (response.result.isKycVerified == false && response.result.kycLink != null && response.result.kycLink != undefined && response.result.kycLink != "") {
        this.kycUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.result.kycLink);
        if (response.result.kycLink.toLowerCase().indexOf('https') == -1 || this.insuranceForm.value.insuranceCompany == 14 || this.insuranceForm.value.insuranceCompany == 9 || this.insuranceForm.value.insuranceCompany == 7 || this.insuranceForm.value.insuranceCompany == 13 || this.insuranceForm.value.insuranceCompany == 4) {
          this.showhttpKYCPopup = true;
          this.VehicleOwnerDetailsComponent.setShowNegativeKycInfo(true);
        }
        else {
          this.showKYCPopup = true;
          this.VehicleOwnerDetailsComponent.setShowNegativeKycInfo(true);
        }
      }
        else if (response?.result?.paymentObject != null && response?.result?.paymentObject?.paymentUrl != null && response?.result?.paymentObject?.paymentUrl != undefined && response?.result?.paymentObject?.paymentUrl != '') {
          this.paymentObject = response?.result?.paymentObject;
          this.IsPolicyDetail = false;
          this.isPaymentLink = true;
          this.IsProposer = false;
          this.isBikeR = false;
          this.preClaim = false;
          // this.router.navigate(['royal',JSON.stringify(response?.result?.royalSundaramResponse)]);
        }
      else
        if (response?.result?.paymentLink && !this.insuranceForm.get('isMockUp')?.value) {
          swal.fire({
            position: 'center',
            icon: 'success',
            title: response?.result?.Message,
            showConfirmButton: false,
            timer: 3000,
          });

          window.open(response?.result?.paymentLink, "_self")
        }
        else if (response?.result?.royalSundaramResponse != null) {
          this.royalDetails = response?.result?.royalSundaramResponse;
          this.IsPolicyDetail = false;
          this.isRoyalSundaram = true;
          this.isBikeR = false;
          this.preClaim = false;
          // this.router.navigate(['royal',JSON.stringify(response?.result?.royalSundaramResponse)]);
        }
        else if (response?.result?.futureGeneralQuoteResponse != null) {
          this.futureDetails = response?.result?.futureGeneralQuoteResponse;
          this.IsPolicyDetail = false;
          this.isFutureGeneral = true;
          this.isBikeR = false;
          this.preClaim = false;
        }
        else if (response?.result?.shriram_GeneralResponse != null) {
          this.ShriramDetails = response?.result?.shriram_GeneralResponse;
          this.IsPolicyDetail = false;
          this.isShriramGeneral = true;
          this.isBikeR = false;
          this.preClaim = false;
        }

        else if (response?.result?.reliance_GeneralResponse != null) {
          this.RelianceDetails = response?.result?.reliance_GeneralResponse;
          this.IsPolicyDetail = false;
          this.isRelianceGeneral = true;
          this.isBikeR = false;
          this.preClaim = false;
        }
        else if (response?.result?.createUniversalSompoResponse != null) {
          const downloadLink = document.createElement('a');
          downloadLink.href = response?.result?.createUniversalSompoResponse.url;
          downloadLink.click();
        }

        else if (response?.result?.bajajallianzResponse != null) {
          const downloadLink = document.createElement('a');
          downloadLink.href = response?.result?.bajajallianzResponse.url;
          downloadLink.click();
        }

        else if (response?.result?.iciciLombardResponse != null) {
          const downloadLink = document.createElement('a');
          downloadLink.href = response?.result?.iciciLombardResponse.url;
          downloadLink.click();
        }


        else {
          swal.fire({
            position: 'center',
            icon: 'success',
            title: `Policy Created Successfully!`,
            showConfirmButton: false,
            timer: 5000,
          });
          this.router.navigate(['policyDetails']);
        }
    }
    else {
      // swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //   title: response?.result?.message,
      //   showConfirmButton: false,
      //   timer: 5000,
      // });

      if (response?.result?.isKycVerified == false && response?.result?.kycLink != null && response?.result?.kycLink != undefined && response?.result?.kycLink != "") {
        this.kycUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.result.kycLink);
        if (response.result.kycLink.toLowerCase().indexOf('https') == -1 || this.insuranceForm.value.insuranceCompany == 14 || this.insuranceForm.value.insuranceCompany == 9 || this.insuranceForm.value.insuranceCompany == 7 || this.insuranceForm.value.insuranceCompany == 13 || this.insuranceForm.value.insuranceCompany == 4) {
          this.showhttpKYCPopup = true;
          this.VehicleOwnerDetailsComponent.setShowNegativeKycInfo(true);
        }
        else {
          this.showKYCPopup = true;
          this.VehicleOwnerDetailsComponent.setShowNegativeKycInfo(true);
        }
        this.insuranceformData.ApplicationId = response.result.applicationId;
        this.insuranceformData.PolicyNumber = response.result.policyNumber;
        this.insuranceformData.url = response.result.kycLink;
      }
      else {
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          // icon: 'error',
          title: '',
          text: response?.result?.message ?? "Something went wrong.",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Ok',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          },
          // timer: 9000,
        });
      }

    }
    this.spinner.hide();
  }

  // Show Quotes Page Redirection
  // ShowQuoteDetials(vehiclePremiumData: any) {
  //   this.insuranceForm.addControl('insuranceCompany', new FormControl('', Validators.required));
  //   console.log('Data received from vehicles quotes component:', vehiclePremiumData);
  //   this.insuranceForm.get('quoteId')?.setValue(vehiclePremiumData.quoteDetails.quoteId);
  //   this.insuranceForm.get('isMockUp')?.setValue(vehiclePremiumData.isMockUp);
  //   this.insuranceForm.get('insuranceCompany')?.setValue(vehiclePremiumData.quoteDetails.insuranceCompany);
  //   this.IsQuotes = false;
  //   this.IsQuoteSelected = true;
  //   setTimeout(async () => await this.VehicleQuoteDetailsComponent.QuoteDetails(vehiclePremiumData), 100);
  // }

  ShowQuoteDetials(vehiclePremiumData: any) {
    this.insuranceForm.addControl('insuranceCompany', new FormControl('', Validators.required));
    console.log('Data received from vehicles quotes component:', vehiclePremiumData);
    this.insuranceForm.get('quoteId')?.setValue(vehiclePremiumData.quoteDetails.quoteId);
    this.insuranceForm.get('isMockUp')?.setValue(vehiclePremiumData.isMockUp);
    this.insuranceForm.get('insuranceCompany')?.setValue(vehiclePremiumData.quoteDetails.insuranceCompany);
    this.IsQuotes = false;
    this.IsQuoteSelected = false;
    this.IsProposer = true;
    this.vehicleRegisterData = vehiclePremiumData.vehicleData;
    // this.showMenu = this.menuService.showMenu();
    // this.appComponent.setShowMenuFlag(this.showMenu);
    // this.homeComponent.showMenu = this.showMenu;
    // //this.vehicleOwnerDetailsComponent.showMenu = this.showMenu;
    setTimeout(async () => await this.VehicleOwnerDetailsComponent.QuoteDetails(vehiclePremiumData, this.vehicleRegisterData), 100);

  }


  // Show Owner Page Redirection
  ShowOwnerDetailsPage(vehiclePremiumData: any) {

    console.log('Data received insurance details component:', vehiclePremiumData);
    this.IsQuotes = false;
    this.IsQuoteSelected = false;
    this.IsProposer = true;
    this.vehicleRegisterData = vehiclePremiumData.vehicleData;
    setTimeout(async () => await this.VehicleOwnerDetailsComponent.QuoteDetails(vehiclePremiumData, this.vehicleRegisterData), 100);
  }

  newBike() {
    alert('hi');
  }

  // Show Policy Details Page Redirection
  ShowPolicyDetailsPage(data: any) {
    console.log('Data received insurance details component:', data);
    this.IsQuotes = false;
    this.IsQuoteSelected = false;
    this.IsProposer = true;
    this.IsPolicyDetail = false;
    this.insuranceForm.get('proposerDetails')?.setValue(data.UserDetails);
    this.insuranceForm.get('nomineeDetails')?.setValue(data.NomineeDetails);
    this.insuranceForm.get('vehicleDetails')?.setValue(data.vehicleData);
    this.insuranceformData = data.insuranceformData;
    this.insuranceformData.proposerDetails = data.UserDetails;
    this.insuranceformData.nomineeDetails = data.NomineeDetails;
    this.insuranceformData.vehicleDetails = data.vehicleData;
    this.insuranceformData.existingTPPolicyDetails = data.existingTPPolicyDetails;
    this.insuranceformData.proposerDetails.city = this.insuranceformData.proposerDetails.district;
    this.CreatePolicy();
    //setTimeout(async () => await this.VehiclePolicyDetailsComponent.QuoteDetails(data.vehiclePremiumData), 100);
  }

  async dontknowbikenumber(value: string) {
    if (value == "2") {
      this.loadInsurancePartners();
      this.isDontKnowVehicleNumber = true;  //new 17-04-2024
      this.verifyBikeDetailspolicy = true; //new 17-04-2024
      this.policy = true; //new 17-04-2024
      this.Type = true; //new 17-04-2024
      //this.preClaim = true;  //new 17-04-2024  
    }
    else {
      this.IsDontKnowBike = true;
    }

    this.newVehicle = value;
    this.IsQuotes = false;
    this.IsQuoteSelected = false;
    this.IsProposer = false;
    this.IsPolicyDetail = false;
    this.isProducts = false;
    this.donthavepolicyDetails = false;
    this.onLabelClick.emit();
  }

  // async DontKnowBikeProposerDetails(data: any) {
  //   ;
  //   this.insuranceForm.get('proposerDetails')?.setValue(data);

  // }

  editSelectedOption(order: number) {
    switch (order) {
      case 1:
        this.policy = true;
        this.Type = false;
        this.preClaim = false;
        break;
      case 2:
        this.policy = false;
        this.Type = true;
        this.preClaim = false;
        break;
      case 3:
        this.policy = false;
        this.Type = false;
        this.preClaim = true;
        break;
    }
  }

  goTo(data: any) {
    if (data == "partner_home") {
      this.IsDontKnowBike = false;
      this.IsQuotes = false;
      this.IsQuoteSelected = false;
      this.IsProposer = false;
      this.IsPolicyDetail = false;
    } else
      if (data == "vehicle_owner") {
        this.IsDontKnowBike = false;
        this.IsQuotes = true;
        this.IsQuoteSelected = false;
        this.IsProposer = false;
        this.IsPolicyDetail = false;
        this.isProducts = false;
        //this.loadVehicleForm();
        //let ddd =this.insuranceForm;
        //this.VehiclequotesComponent.GetQuoteInfo(this.insuranceForm.value);
        //this.insuranceservice.QuotesValue(this.insuranceForm.value);
      }
      else if (data == "vehicle_quote") {
        if (this.router.url != "/") {
          this.router.navigate(['']);
        }
        else {
          this.IsDontKnowBike = false;
          this.IsQuotes = false;
          this.IsQuoteSelected = false;
          this.IsProposer = false;
          this.IsPolicyDetail = false;
          this.isProducts = false;
          //this.insuranceservice.QuotesValue(this.insuranceForm.value);
          // //this.showMenu = this.menuService.showMenu();
          // this.appComponent.setShowMenuFlag(false);
          // this.homeComponent.showMenu = false;
          // this.homeComponent.DisplayInsurance = false;
          // this.homeComponent.currentTab ="Bike";
        }

      }
  }

  toggleViewQuotesButton() {
    if ((parseInt(this.policyExpiryStatus) > 0 && parseInt(this.policyType) > 0 && this.policyClaimed == 2 && parseInt(this.policyClaimedNCB) < 100)
      || (parseInt(this.policyExpiryStatus) > 0 && parseInt(this.policyType) > 0 && this.policyClaimed == 1)
      || (parseInt(this.policyExpiryStatus) > 0 && this.policyType == 3)
    ) {
      this.showViewQuotesButton = true;
    }
    else {
      this.showViewQuotesButton = false;
    }
  }

  toggleNCBPercentage() {
    //
    // if(parseInt(this.policyExpiryStatus) > 2 || this.vehicleForm.get('previousPolicyType')?.value == "Thirdparty"){
    //   this.claimPercentage=false; 
    // }
    //else 
    if ((parseInt(this.policyExpiryStatus) > 0 && parseInt(this.policyExpiryStatus) < 3) && parseInt(this.policyType) == 1 && parseInt(this.policyClaimed) == 2) {
      this.claimPercentage = true;
    }
    else {
      this.claimPercentage = false;
    }

    if (parseInt(this.policyExpiryStatus) > 0 && parseInt(this.policyExpiryStatus) < 3) {
      this.showPreviousPolicyCompany = true;
    }
    else {
      this.showPreviousPolicyCompany = false;
    }
  }

  async loadInsurancePartners() {
    let response = await this._lookupService.GetInsurancePartnersList('m');
    if (response?.isSuccess) {
      this.insurancePartnersList = response.result.map((m: any) => { return { text: m.insuranceName, value: m.insuranceName, id: m.insuranceName } });
      if (this.insurancePartnersList.filter((f: any) => { return f.text == this.vehicleForm.get('previousPolicyCompany')?.value })?.length > 0) {
        this.previousPolicyCompany = this.vehicleForm.get('previousPolicyCompany')?.value;
      }
    }
  }

  async loadMakeList(searchByVahanData: boolean = false, name: string, modelName: string) {
    name = name ?? "";
    let request = { Type: 2, Name: (searchByVahanData == true ? name : ''), searchByVahanData: searchByVahanData };
    const res = await this.vehicleService.GetVehicleCompanyDetails(request);
    if (res != null && res.result) {
      if (searchByVahanData == false) {
        this.vehicleMakeList = res.result.map((item: any) => { return { text: item, value: item, id: item } });
      }
      else {
        ;
        if (res.result[0] != null && res.result[0] != undefined && res.result[0] != "") {
          this.vehicleForm.get('make')?.setValue(res.result[0]);
          this.loadModelListByMake('');
          setTimeout(() => {
            this.loadModelListByMake(modelName, true)
          }, 200);
        }
      }
    }
  }

  async loadModelListByMake(model: string, searchByVahanData: boolean = false) {
    model = model ?? "";
    let request = { Type: 2, CompanyName: this.vehicleFormControl['make'].value, Name: (searchByVahanData == true ? model : ''), searchByVahanData: searchByVahanData };
    const res = await this.vehicleService.GetVehicleModelDetails(request);
    if (res != null && res.result) {
      if (searchByVahanData == false) {
        this.vehicleModelList = res.result.map((item: any) => { return { text: item, value: item, id: item } });
      }
      else {
        if (res.result[0] != null && res.result[0] != undefined && res.result[0] != "") {
          this.vehicleForm.get('model')?.setValue(res.result[0]);
          this.loadVariantListByModel(this.vehicleForm.get('model')?.value);
        }

      }
    }
  }

  async loadVariantListByModel(model: string) {
    let request = { ModelName: model };
    const res = await this.vehicleService.GetVehicleVariantDetails(request);
    if (res != null && res.result) {
      this.vehicleVariantList = res.result.map((item: any) => { return { text: item, value: item, id: item } });
    }
  }

  previousPolicyCompanyChange(data: any) {
    this.vehicleForm.get('previousPolicyCompany')?.setValue(data.target.value);

  }

  openNCBModal() {
    this.showNCBTooltip = true;
  }

  closeNCBModal() {
    this.showNCBTooltip = false;
  }

  openPolicyStatusModal() {
    this.showPolicyStatusTooltip = true;
  }

  closePolicyStatusModal() {
    this.showPolicyStatusTooltip = false;
  }

  onPrevCompSelection(data: AutoCompleteItem) {
    this.vehicleForm.get('previousPolicyCompany')?.setValue(data.text);
    this.previousPolicyCompany = data.text;
  }

  onPrevCompBlur(data: any) {
    this.vehicleForm.get('previousPolicyCompany')?.setValue(data);
    this.previousPolicyCompany = data;
  }

  get vehicleFormControl() {
    return this.vehicleForm.controls;
  }
  onMakeSelection(data: AutoCompleteItem) {
    this.vehicleForm.get('make')?.setValue(data.text);
    this.loadModelListByMake('');
  }
  onModelSelection(data: AutoCompleteItem) {
    this.vehicleForm.get('model')?.setValue(data.text);
    this.loadVariantListByModel(this.vehicleForm.get('model')?.value)
  }
  onVariantSelection(data: AutoCompleteItem) {
    this.vehicleForm.get('variant')?.setValue(data.text);
  }
  onMakeBlur(data: any) {
    this.vehicleForm.get('make')?.setValue(data);
    this.loadModelListByMake('');
  }
  onModelBlur(data: any) {
    this.vehicleForm.get('model')?.setValue(data);
    this.loadVariantListByModel(this.vehicleForm.get('model')?.value)
  }
  onVariantBlur(data: any) {
    this.vehicleForm.get('variant')?.setValue(data);
  }

  async onPincodeChange(data: any) {
    if (data.length >= 3) {
      const res = await this.lookUpService.pincodeMasterDetails(data);
      if (res != null && res.result) {
        this.pincodeList = res.result.map((item: any) => { return { text: item.pincode, value: item.pincode, text1: item.cityVillageArea, id: item.id, pincodeMaster: item } });
      }
    }
    this.vehicleForm.get('pincode')?.setValue(data);
  }

  onPincodeSelection(data: AutoCompleteItem) {
    this.vehicleForm.get('pincode')?.setValue(data.pincodeMaster?.pincode);
  }

  onInputChange(event: any): void {
    const initialValue = event.target.value;
    const input = event.target as HTMLInputElement;

    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (input.value.length > 6) {
      input.value = input.value.slice(0, 6);
      event.stopPropagation();

    }
    if (initialValue !== event.target.value) {
      event.stopPropagation();

    }
  }

  onPincodeBlur(data: any) {
    console.log(data);
    this.vehicleForm.get('pincode')?.setValue(data);
  }

  closeBreakInModal() {
    this.showIsBreakIn = false;
  }

  goToPolicyDetails(){
    this.router.navigate(['policyDetails']);
  }

  isTyping = !0;
//focus
onFocusInput(e: any) {
    //document.getElementById(id).placeholder = "";
}
//key down
formatRegNo(e:any) {
  if ((e && e.keyCode && e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode >= 65 && e.keyCode <= 90) &&
  navigator.userAgent.match(/UCBrowser/))
      return !1;
  var t = e.keyCode;
  if (-1 != navigator.userAgent.indexOf("MSIE"))// || 1 == !!document.documentMode) 
    {
      if (32 == t)// || 8 == t)
          return !1;
      var o = e.target.value;
      o.length >= 3 && (o = this.formatReg(o)),
      o.length >= 5 && (o = this.formatReg(o)),
      o.length >= 7 && (o = this.formatReg(o)),
      e.target.value = o
  } else
      //setTimeout(function(){
          if (32 == t)// || 8 == t)
              return !1;
          var ev = e.target.value;
          ev.length >= 3 && (ev = this.formatReg(ev)),
          ev.length >= 5 && (ev = this.formatReg(ev)),
          ev.length >= 7 && (ev = this.formatReg(ev)),
          e.target.value = ev;
          return 1;
      //}, 100)
    }
//paste and type
  checkRegistrationNumber(e:any) {
    this.isTyping && (this.isTyping = !1),
    e.target.value = e.target.value.replace(/[^a-zA-Z0-9\- ]+/g, '');
    e.target.value = e.target.value.toUpperCase()
    if(e.target.value && e.target.value.includes('-')){
      var temp_regno = e.target.value.split('-');
    setTimeout(function(){
    var temp_regno = e.target.value.split('-');
    if(temp_regno && temp_regno.length > 3 && temp_regno[3] && temp_regno[3].length > 4){
      e.target.value = (e.target.value.substring(0, e.target.value.length - 1));
      }
    },100)
    }
    this.onVehicleRegNumberInput();
  }

  formatReg(value:any) {
    if(value != null && value != undefined && value != "" ){
      var t = value;//value != null && value != undefined && value != "" ? value : e.target.value;
      t = t.replace(/-/g, "");
      var o = new Array(4);
      o[0] = t.slice(0, 2),
      o[1] = "",
      o[2] = "",
      o[3] = "";
      try {
          isNaN(t.slice(2, 4)) ? (isNaN(t.slice(2, 3)) || (o[1] = t.slice(2, 3)),
          isNaN(t.slice(3, 4)) && (o[2] = t.slice(3, 4))) : o[1] = t.slice(2, 4),
          isNaN(t.slice(4, 8)) ? (o[2] = o[2] + t.slice(4, 5),
          isNaN(t.slice(5, 6)) && isNaN(t.slice(6, 7)) ? o[2] = o[2] + t.slice(5, 7) : (isNaN(t.slice(5, 6)) ? o[2] = o[2] + t.slice(5, 6) : o[3] = o[3] + t.slice(5, 6),
          isNaN(t.slice(6, 7)) || (o[3] = o[3] + t.slice(6, 7))),
          isNaN(t.slice(7, 11)) || (o[3] = o[3] + t.slice(7, 11))) : o[3] = o[3] + t.slice(4, 8)
      } catch (e) {}
      return o.join("-").replace(/ /g, "").replace(/--/g, "-").replace(/-\s*$/, "").replace(/[^- 0-9 a-z A-z]/g, "")
    }
      return value;
  }
  claimCountChanged(nsbVal: any): void {
    let claimCount = nsbVal.target.value;
    this.vehicleForm.get('claimsMadeInPreviousPolicyNo')?.setValue(claimCount);
    this.claimsMadeInPreviousPolicyNo = claimCount;
  }
}
function toggleDivs() {
  throw new Error('Function not implemented.');
}



